import { CircularProgress, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom"
import Fade from '@mui/material/Fade';
import AuthDialog from "components/AuthDialog";
import { UserDataContext } from "libs/auth/providers";
import { Stack } from "@mui/material";
import { Claims, UserType } from "libs/auth/models";
import { AuthDialogContext } from "components/Layout/Layout";
import { useAuthService } from "libs/auth/hooks";


const LoggedInMenu: FC<{ userData: Claims, onOpen?: () => void }> = ({ userData, onOpen }) => {
    const { refreshData } = useContext(UserDataContext)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const authService = useAuthService();

    const theme = useTheme()

    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false)

    const navigate = useNavigate()

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logout = async () => {
        setIsLoggingOut(true)
        await authService.logout()
        await refreshData()
        setIsLoggingOut(false)
        handleClose()
    }

    const navigateMyRequests = () => {
        onOpen && onOpen()
        navigate("/mano/paraiskos")
        handleClose()
    }


    const navigateAdmin = () => {
        onOpen && onOpen()
        navigate(userData.type === UserType.Creditor ? "/creditor-admin/credit-requests" : "/admin/credit-requests")
        handleClose()
    }

    const showMenu = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const isAdmin = userData.type !== UserType.Simple

    if (showMenu) {
        return <>
            <Stack sx={{ p: "10px 16px" }}>
                <Typography sx={{ fontSize: "18px" }}>{userData.firstName} {userData.lastName}</Typography>
                <Typography sx={{ fontSize: "11px" }}>{userData.email}</Typography>
            </Stack>
            {isAdmin && <MenuItem onClick={navigateAdmin}>Administravimas</MenuItem>}
            <MenuItem onClick={navigateMyRequests}>Mano paraiškos</MenuItem>
            <MenuItem disabled={isLoggingOut} onClick={logout}><ListItemText>Atsijungti</ListItemText>{isLoggingOut && <ListItemIcon><CircularProgress size={20} /></ListItemIcon>}</MenuItem>
        </>
    }

    return <>
        <IconButton onClick={handleClick}>
            <AccountCircleIcon sx={{ color: theme.palette.primary.main, fontSize: "40px" }} />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <Stack sx={{ p: "10px 16px" }}>
                <Typography sx={{ fontSize: "18px" }}>{userData.firstName} {userData.lastName}</Typography>
                <Typography sx={{ fontSize: "11px" }}>{userData.email}</Typography>
            </Stack>
            <Divider />
            {isAdmin && <MenuItem onClick={navigateAdmin}>Administravimas</MenuItem>}
            <MenuItem onClick={navigateMyRequests}>Mano paraiškos</MenuItem>
            <MenuItem disabled={isLoggingOut} onClick={logout}><ListItemText>Atsijungti</ListItemText>{isLoggingOut && <ListItemIcon><CircularProgress size={20} /></ListItemIcon>}</MenuItem>
        </Menu>
    </>
}

const UserMenu: FC<{ onOpen?: () => void }> = ({ onOpen }) => {
    const { userData } = useContext(UserDataContext)

    const { open, setOpen } = useContext(AuthDialogContext)

    if (userData) {
        return <LoggedInMenu onOpen={onOpen} userData={userData} />
    }

    return <>
        <IconButton onClick={() => {
            setOpen(true)
            onOpen && onOpen()
        }}>
            <AccountCircleIcon sx={{ color: "grey", fontSize: "40px" }} />
        </IconButton>
        <AuthDialog open={open} onClose={() => setOpen(false)} />
    </>
}


export default UserMenu