import { AppBar, Box, CircularProgress, CssBaseline, Stack, Toolbar } from "@mui/material"
import { ThemeProvider } from "@mui/system";
import { Link, Outlet, useNavigate } from "react-router-dom"
import { createTheme } from '@mui/material/styles';
import logo from 'assets/img/logo.png';
import UserMenu from "components/UserMenu";
import { useContext, useMemo } from "react";
import { UserDataContext } from "libs/auth/providers";
import { Claims, UserType } from "libs/auth/models";
import Menu, { MenuItemData } from "components/Menu/Menu";

const theme = createTheme({
    palette: {
        background: {
            default: "#F9F8F8"
        },
        primary: {
            main: "#34a18e",
        },
        info: {
            main: "#000000"
        }
    }
});
const getMenuItems = (userData: Claims | null): MenuItemData[] => {
    if (!userData) {
        return []
    }
    if (userData.type === UserType.Creditor) {
        return [
            {
                path: "/creditor-admin/credit-requests",
                name: "Paraiškos",
            }

        ]
    }

    return [
        {
            path: "/admin/credit-requests",
            name: "Paraiškos",
        },
        {
            path: "/admin/creditors",
            name: "Kreditoriai",
        },
        {
            path: "/admin/posts",
            name: "Naujienos",
        },
        {
            path: "/admin/pages",
            name: "Puslapiai",
        },
        {
            path: "/admin/settings",
            name: "Nustatymai",
        }

    ]
}

const AdminLayout = () => {

    const { initialLoading, userData } = useContext(UserDataContext)

    const navigate = useNavigate()
    const menuItems = useMemo(() => getMenuItems(userData), [userData])

    if (initialLoading) {
        return <Stack alignItems="center"><CircularProgress /></Stack>
    }

    if (!userData || userData.type === UserType.Simple) {
        navigate("/")
        return <Stack alignItems="center"><CircularProgress /></Stack>
    }



    return <>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="sticky">
                <Toolbar sx={{ backgroundColor: "white", display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <Stack direction="column" alignItems="center" sx={{ width: "100%" }}>
                        <Stack direction="row" sx={{ justifyContent: "space-between", width: "1500px", alignItems: "center" }}>
                            <Link to={userData.type === UserType.Creditor ? "/creditor/admin/credit-requests" : "/admin/credit-requests"}>
                                <img src={logo} style={{ maxHeight: "25px" }} alt="" />
                            </Link>
                            <Stack direction={"row"} sx={{ alignItems: "center" }} gap={3}>
                                <Menu menuItems={menuItems} />
                                <UserMenu />
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Stack direction="column" sx={{ alignItems: "center", pt: "40px" }}>
                <Box sx={{ width: "1500px" }}>
                    <Outlet />
                </Box>
            </Stack>
        </ThemeProvider>
    </>
}


export default AdminLayout