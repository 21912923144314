import { Response } from "api/models";
import { Page } from "libs/pages/models";
import { AxiosService } from "libs/utils/AxiosService";
import { MenuUpdateForm } from "./models";


export class MenuApiService extends AxiosService {
    endpoint: string = "/api/menu";
    adminEndpoint: string = "/api/admin/menu";

    getMenu(key: string): Promise<Page[]> {
        return this.axios.get<Response<Page[]>>(`${this.endpoint}/${key}`).then(x => x.data.data)
    }
    updateMenu(key: string, form: MenuUpdateForm): Promise<void> {
        return this.axios.post(`${this.adminEndpoint}/${key}`, form)
    }
}
