import { CountRequest, DataService, ListRequest, Sorting, useDatatable } from "@brunas/react-mui-datatable";
import { GridValidRowModel } from "@mui/x-data-grid";
import { AxiosInstance } from "axios";
import { UserDataContext } from "libs/auth/providers";
import { AxiosService } from "libs/utils/AxiosService";
import { useContext, useMemo } from "react";

type Response<T> = {
    status: number,
    errorCode: string | null,
    errorMessage: string | null,
    data: T
}

class DatatableApiService<T> extends AxiosService implements DataService<T, null>{
    datatableEndpoint: string;

    constructor(axios: AxiosInstance, endpoint: string) {
        super(axios)
        this.datatableEndpoint = `/api${endpoint}`
    }

    getDatatableList(request: ListRequest): Promise<T[]> {
        return this.axios.post<Response<T[]>>(`${this.datatableEndpoint}/list`, request).then(value => {
            return value.data.data
        })
    }
    
    getDatatableCount(request: CountRequest): Promise<number> {
        return this.axios.post<Response<number>>(`${this.datatableEndpoint}/count`, request).then(value => value.data.data)
    }
}

type DatatableApiProps<Config extends unknown> = {
    config: Config;
    defaultSorting?: Sorting[];
    endpoint:string
}

export const useDatatableWithApi = <T extends GridValidRowModel, C extends unknown>( {endpoint, ...props}: DatatableApiProps<C>) => {
    const { authorizedAxios } = useContext(UserDataContext)

    const service = useMemo(() => new DatatableApiService<T>(authorizedAxios, endpoint), [authorizedAxios, endpoint])

    return useDatatable<T, C>({ service: service, ...props })
}