import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Button, Stack, TextField, Typography } from "@mui/material"
import { getErrorText } from "api/errors"
import { useAuthService } from "libs/auth/hooks"
import { RegisterForm } from "libs/auth/models"
import isEmail from "libs/utils/isEmail"
import { FC, useState } from "react"



type FullRegisterForm = RegisterForm & {
    password2: string
}

const minPasswordLength = 8

const RegisterContent: FC<{ onClose: () => void, openLogin: () => void }> = ({ onClose, openLogin }) => {
    const [form, setForm] = useState<FullRegisterForm>({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        password2: ""
    })

    const authService = useAuthService();
    const [isRegistering, setIsRegistering] = useState<boolean>(false)
    const [registerError, setRegisterError] = useState<string>("")
    const [showRegistered, setShowRegistered] = useState<boolean>(false)

    const updateField = (field: string, value: string) => {
        setForm({ ...form, [field]: value })
    }


    const validate = (): string | null => {
        const emptyValues = Object.values(form).filter(item => !item)
        if (emptyValues.length) {
            return "Nepalikite tuščių laukelių"
        }

        if (!isEmail(form.email)) {
            return "Klaidingas el. pašto adresas"
        }

        if (form.password !== form.password2) {
            return "Nesutampa slaptažodžiai"
        }

        if (form.password.length < minPasswordLength) {
            return `Slaptažodį turi sudaryti bent ${minPasswordLength} simboliai`
        }

        return null
    }

    const register = async () => {
        setRegisterError("")
        const frontError = validate()
        if (frontError) {
            setRegisterError(frontError)
            return
        }
        setIsRegistering(true)

        try {
            await authService.register(form as RegisterForm)
            setShowRegistered(true)
        } catch (e: any) {
            const error = e.response.data.errorCode
            setRegisterError(getErrorText(error))
        }
        setIsRegistering(false)
    }

    return <Stack gap={2} sx={{ pt: "5px" }}>
        {showRegistered ?
            <>
                <Alert severity="success">Sėkmingai užsiregistravote. Patikrinkite el. paštą ir pavirtinkite registraciją</Alert>
                <Button variant="contained" onClick={openLogin}>Prisijungti</Button>
            </>
            :
            <>
                <TextField label={"Vardas"} value={form.firstName} onChange={e => updateField("firstName", e.target.value)} />
                <TextField label={"Pavardė"} value={form.lastName} onChange={e => updateField("lastName", e.target.value)} />
                <TextField label={"El. paštas"} value={form.email} onChange={e => updateField("email", e.target.value)} />
                <TextField label={"Slaptažodis"} value={form.password} onChange={e => updateField("password", e.target.value)} type="password" />
                <TextField label={"Pakartokite slaptažodį"} value={form.password2} onChange={e => updateField("password2", e.target.value)} type="password" />
                {registerError && <Alert severity="error">{registerError}</Alert>}
                <LoadingButton loading={isRegistering} variant={"contained"} onClick={register}>Registruotis</LoadingButton>


                <Stack direction="row" alignItems={"center"} gap={1}>
                    <Typography>Jau užsiregistravote? </Typography>
                    <Button onClick={openLogin}>Prisijungti</Button>
                </Stack>
            </>
        }

    </Stack>
}

export default RegisterContent