import { Alert, Autocomplete, CircularProgress, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, TextField } from "@mui/material"
import { useMenuService } from "libs/menu/hooks"
import { Page } from "libs/pages/models"
import { FC, useEffect, useMemo, useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete';
import { getErrorText } from "api/errors";
import LoadingButton from "@mui/lab/LoadingButton";

const Menu: FC<{ menuKey: string, title: string, pages: Page[] }> = ({ menuKey, title, pages }) => {
    const [menu, setMenu] = useState<string[]>([])

    const [loaded, setLoaded] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [updateError, setUpdateError] = useState<string | null>("")

    const service = useMenuService()



    useEffect(() => {
        const fetch = async () => {

            try {
                const result = await service.getMenu(menuKey)
                setMenu(result.map(page => page.id))
                setLoaded(true)
            } catch (e: any) {

            }
        }

        fetch()
    }, [service, menuKey])


    const unusedPages = useMemo(() => pages.filter(page => !menu.includes(page.id)), [menu, pages])
    const usedPages = useMemo(() => pages.filter(page => menu.includes(page.id)), [menu, pages])


    const addPage = (page: Page) => {
        setMenu(current => [...current, page.id])
        setInputValue("")
    }

    const removePage = (id: string) => {
        setMenu(current => current.filter(item => item !== id))
    }

    const [inputValue, setInputValue] = useState('');

    const updateMenu = async () => {

        setUpdateError(null)
        setSubmitting(true)
        try {
            await service.updateMenu(menuKey, { pages: menu })
        } catch (e: any) {
            setUpdateError(getErrorText(e.response.data.errorCode))
        }
        setSubmitting(false)
    }

    return <Paper sx={{ width: "100%", p: "20px" }}>
        <h2>{title}</h2>
        {loaded ?
            <Stack gap={2}>
                {usedPages.length > 0 && <>
                    <List>
                        {usedPages.map(page => <ListItem key={page.id}>
                            <ListItemText primary={page.title} />
                            <ListItemIcon>
                                <IconButton onClick={() => removePage(page.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemIcon>
                        </ListItem>)}
                    </List>
                    <Divider />
                </>}
                <Autocomplete
                    value={null}
                    onChange={(event: any, newValue: Page | null) => {
                        newValue && addPage(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    getOptionLabel={page => page.title}
                    options={unusedPages}
                    renderInput={(params) => <TextField {...params} label="Pridėti meniu punktą" />}
                />
                {updateError && <Alert severity="error">{updateError}</Alert>}
                <LoadingButton loading={submitting} variant="contained" onClick={updateMenu}>Saugoti</LoadingButton>
            </Stack>
            :
            <Stack alignItems="center"><CircularProgress /></Stack>
        }
    </Paper>
}

export default Menu