import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Button, Stack, TextField } from "@mui/material"
import { getErrorText } from "api/errors"
import { useAuthService } from "libs/auth/hooks"
import { FC, useState } from "react"



const PasswordResetInit: FC<{ onClose: () => void, openLogin: () => void }> = ({ onClose, openLogin }) => {
    const [email, setEmail] = useState<string>("")

    const authService = useAuthService();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string>("")
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    const reset = async () => {
        setSubmitError("")
        setIsSubmitting(true)

        try {
            await authService.startRecover(email)
            setShowSuccess(true)
        } catch (e: any) {
            const error = e.response.data.errorCode
            setSubmitError(getErrorText(error))
        }
        setIsSubmitting(false)
    }

    return <Stack gap={2} sx={{ pt: "5px" }}>
        {showSuccess ?
            <>
                <Alert severity="success">Slaptžodžio atstatymą pratęsite paspaudę nuorodą esančią nurodytu el. paštu nusiųstame laiške</Alert>
                <Button variant="contained" onClick={onClose}>Uždaryti</Button>
            </>
            :
            <>
                <TextField label={"El. paštas"} value={email} onChange={e => setEmail(e.target.value)} />
                {submitError && <Alert severity="error">{submitError}</Alert>}
                <LoadingButton loading={isSubmitting} variant={"contained"} onClick={reset}>Atstatyti</LoadingButton>
                <Button variant="outlined" onClick={openLogin}>Prisijungti</Button>
            </>
        }

    </Stack>
}

export default PasswordResetInit