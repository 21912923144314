import { Dayjs } from "dayjs"
import { FC } from "react"
import FormBusiness from "views/Form/FormBusiness"
import FormCarLeasing from "views/Form/FormCarLeasing"
import FormCredit from "views/Form/FormCredit"


export enum RequestTypeEnum {
    Credit = "credit",
    Business = "business",
    CarLeasing = "car-leasing"
}

export enum RequestStatusEnum {
    New = "new",
    InProgress = "in-progress",
    Closed = "closed",
}

export enum CreditorResponseStatus {
    Accepted = "accepted",
    Declined = "declined",
    PreSigned = "pre-signed",
    Signed = "signed",
}

export type CreditorCreditRequestDatatableItem = {
    id: string,
    number: string,
    requestAuthor: string,
    type: RequestTypeEnum,
    date: Dayjs,
    amount: number,
    periodMonths: number,
    status: CreditorResponseStatus | null,
    responseAuthor: string | null,
    responseDate: Dayjs | null,
    yearInterest: number | null,
    contractPrice: number | null,
}


export type MyRequestDatatableItem = {
    id: string,
    number: string,
    date: Dayjs,
    type: RequestTypeEnum,
    amount: number,
    periodMonths: number,
    status: RequestStatusEnum,
    signed: boolean,
    responsesCount: number,
    paid: boolean,
    contract: boolean
}



export type RequestDatatableItem = {
    id: string,
    number: string,
    date: Dayjs,
    type: RequestTypeEnum,
    creator: string,
    amount: number,
    periodMonths: number,
    status: RequestStatusEnum,
    signed: boolean,
    responsesCount: number,
}


export type CreditorDatatableItem = {
    id: string,
    title: string,
    users: number,
}


type RequestTypeData = {
    type: RequestTypeEnum,
    name: string,
    fullPath: string,
    path: string,
    component: FC,
    infoTitle: string,
    infoDescription: string,
}

export const RequestTypeList: RequestTypeData[] = [
    {
        type: RequestTypeEnum.Credit,
        name: "Vartojimo paskola",
        fullPath: "/formos/vartojimo-paskola",
        path: "vartojimo-paskola",
        component: FormCredit,
        infoTitle: "Gauk geriausią vartojimo paskolos pasiūlymą!",
        infoDescription: "Per vieną valandą patikrinsim daugiau nei 10 kredito įstaigų ir pateiksim geriausią pasiūlymą",
    },
    {
        type: RequestTypeEnum.Business,
        name: "Paskola verslui",
        fullPath: "/formos/paskola-verslui",
        path: "paskola-verslui",
        component: FormBusiness,
        infoTitle: "Paskolos verslui iki 2 mln €",
        infoDescription: "Ieškote finansavimo savo verslo idėjoms? 1kreditas siūlo greitas ir patikimas paskolas verslui! Nesvarbu, ar norite įsigyti naują įrangą, finansuoti darbuotojų mokėjimus arba plėsti savo veiklą - mes turime sprendimą, kuris jums tinka!",
    },
    {
        type: RequestTypeEnum.CarLeasing,
        name: "Automobilio lizingas",
        fullPath: "/formos/automobilio-lizingas",
        path: "automobilio-lizingas",
        component: FormCarLeasing,
        infoTitle: "Važiuok su tvarkingu ir saugiu automobiliu jau šiandien",
        infoDescription: "Per vieną valandą pateiksime Jums geriausias paskolos pasiūlymus iš Lietuvos kredito įstaigų",
    }
]



export type LoginRequest = {
    email: string,
    password: string,
}


export type BusinessRequestData = {
    companyName: string
}

export type CreditRequestData = {
    married: boolean,
    personCode: string,
    firstName: string,
    lastName: string,
    documentType: string | null,
    incomeType: string | null,
    income: string,
    otherIncome: string,
    monthlyCreditOutcome: string,
    monthlyOtherOutcome: string,
}

export type CarLeasingRequestData = CreditRequestData & {
    carBrand: string,
    carYear: string
}

export type CreditRequestForm = {
    amount: number,
    periodMonths: number,
    email: string,
    phone: string,
    type: RequestTypeEnum,
    data: BusinessRequestData | CreditRequestData | CarLeasingRequestData | null
}

export type PaymentRequestData = {
    cancelUrl: string,
    acceptUrl: string,
}


export type FullCreditRequest = {
    id: string,
    number: string,
    periodMonths: number,
    status: RequestStatusEnum,
    type: RequestTypeEnum,
    date: Dayjs,
    contractFile:string|null,
    phone: string,
    email: string,
    data: BusinessRequestData | CreditRequestData | CarLeasingRequestData,
    creator: string,
    amount: number,
    paid: boolean,
    signedResponse: CreditorResponse | null,
}

export type CreditorResponse = {
    id: string,
    status: CreditorResponseStatus,
    date: Dayjs,
    yearInterest: number | null,
    contractPrice: number | null,
    declineReason: string | null,
    creditor: string
}

export type CreditorResponseForm = {
    status: CreditorResponseStatus,
    yearInterest: number | null,
    contractPrice: number | null,
    declineReason: string | null
}


export type CreditorForm = {
    title: string,
}

export type Creditor = {
    id: string,
    title: string,
}
type SelectOption = {
    value: string,
    label: string
}


export const DocumentTypes: SelectOption[] = [
    {
        value: "id-card",
        label: "Asmens tapatybės kortelė"
    },
    {
        value: "passport",
        label: "Pasas"
    },
    {
        value: "permanent-residence-permit-card",
        label: "Leidimas nuolat gyventi Lietuvoje"
    },
    {
        value: "eu-residence-permit-card",
        label: "ES piliečiui išduota pažyma dėl teisės gyventi Lietuvoje"
    }
]

export const IncomeType: SelectOption[] = [
    {
        value: "contract",
        label: "Dirbantis"
    },
    {
        value: "abroad",
        label: "Darbas užsienyje"
    },
    {
        value: "civil-servant",
        label: "Vastybės tarnautojas"
    },
    {
        value: "individual-activity",
        label: "Induviduali veikla/verslo liudijimas"
    },
    {
        value: "business",
        label: "Verslininkas"
    },
    {
        value: "army",
        label: "Karo tarnybą atliekantis asmuo"
    },
    {
        value: "child-care",
        label: "Vaiko priežiūros atostogos"
    },
    {
        value: "student",
        label: "Studentas"
    },
    {
        value: "unemployed",
        label: "Nedirbantis"
    },
    {
        value: "retiree",
        label: "Pensininkas"
    },
    {
        value: "working-retiree",
        label: "(Dirbantis) pensininkas"
    },
]
