import { Column, Datatable, Sorting, SortingDirectionEnum } from "@brunas/react-mui-datatable"
import { Alert, Button, Chip, Stack, Typography } from "@mui/material"
import { useCreditService } from "libs/credit/hooks"
import { MyRequestDatatableItem, RequestStatusEnum, RequestTypeList } from "libs/credit/models"
import { useDatatableWithApi } from "libs/datatable/hooks"
import { FC, useCallback, useMemo } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const defaultSorting: Sorting[] = [
    {
        field: "date",
        direction: SortingDirectionEnum.Descending
    }
]

const StatusBadge: FC<{ item: MyRequestDatatableItem }> = ({ item }) => {
    if (item.signed) {
        return <Chip color="success" label="Sudaryta sutartis" />
    }
    switch (item.status) {
        case RequestStatusEnum.New:
            return <>Laukiama patvirtinimo</>
        case RequestStatusEnum.InProgress:
            if (item.responsesCount > 0) {
                return <Chip color="warning" label="Gautas pasiūlymas" />
            }
            return <>Vertinami pasiūlymai</>
        case RequestStatusEnum.Closed:
            return <Chip color="error" label="Uždaryta" />
    }
}


const MyReqeustsDatatable = () => {
    const data = useDatatableWithApi<MyRequestDatatableItem, null>({ endpoint: "/me/requests/datatable", config: null, defaultSorting: defaultSorting })

    const [searchParams] = useSearchParams()

    const navigate = useNavigate()

    const fromPayment = !!searchParams.get("data")
    const code = searchParams.get("code")

    const service = useCreditService()

    const redirectToPayment = useCallback(async (id: string) => {
        try {
            const url = await service.generatePaymentUrl(id, { acceptUrl: window.location.href, cancelUrl: window.location.href })

            window.open(url, '_blank', 'noreferrer');
        } catch (e) {
            //TODO error
        }
    }, [service])

    const renderPaidColumn = useCallback((item: MyRequestDatatableItem) => {
        if (item.paid) {
            return <Stack>
                <Typography>Apmokėta</Typography>
                {item.contract && <a rel="noreferrer" target="_blank" href={service.getContractDownloadUrl(item.id, code)}>Sąskaita</a>}
            </Stack>
        }

        return <Button onClick={() => redirectToPayment(item.id)} variant="outlined">Mokėti</Button>
    }, [redirectToPayment, code, service])

    const columns = useMemo<Column<MyRequestDatatableItem>[]>(() => [
        {
            value: (item: MyRequestDatatableItem) => `#${item.number}`,
            header: "Numeris"
        },
        {
            value: (item: MyRequestDatatableItem) => RequestTypeList.find(x => x.type === item.type)?.name,
            header: "Paskolos tipas"
        },
        {
            value: (item: MyRequestDatatableItem) => item.date.format("YYYY-MM-DD"),
            header: "Data",
            sortingField: "date",
            width: 120
        },
        {
            value: (item: MyRequestDatatableItem) => `${item.amount} €`,
            header: "Paskolos suma"
        },
        {
            value: (item: MyRequestDatatableItem) => `${item.periodMonths} mėn.`,
            header: "Periodas",
            width: 80
        },
        {
            value: (item: MyRequestDatatableItem) => <StatusBadge item={item} />,
            header: "Būklė",
            width:180
        },
        {
            value: renderPaidColumn,
            header: "Apmokėjimas",
            width: 120
        },
        {
            header: "Veiksmai",
            getActions: (params) => {
                if (params.row.status !== RequestStatusEnum.InProgress) {
                    return []
                }
                return [
                    <Button onClick={() => navigate(`/mano/paraiskos/${params.row.id}`)}>Peržiūrėti</Button>
                ]
            }
        }
    ], [renderPaidColumn, navigate])
    return <>
        {fromPayment && <Alert variant="outlined" severity="success">Apmokėjimas atliktas, apdorojimas gali užtrukti kelias minutes</Alert>}
        <Datatable {...data} globalSearchEnabled={false} columns={columns} />
    </>
}

export default MyReqeustsDatatable