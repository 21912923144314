import { Alert, Button, Chip, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { getErrorText } from "api/errors"
import { useCreditService } from "libs/credit/hooks"
import { CreditorResponse, CreditorResponseStatus } from "libs/credit/models"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"


const RequestResponses: FC<{ id: string, refreshData: () => void, setSignSuccess: () => void }> = ({ id, refreshData, setSignSuccess }) => {

    const [responses, setResponses] = useState<CreditorResponse[] | null>(null)

    const [searchParams] = useSearchParams()

    const service = useCreditService()

    const [preSigningResponseId, setPreSigningResponseId] = useState<string | null>(null)
    const [preSigningError, setPreSigningError] = useState<string | null>(null)

    const viewCode = searchParams.get('code')

    useEffect(() => {
        const fetchResponses = async () => {
            setResponses(await service.getRequestResponses(id, viewCode))
        }
        fetchResponses()
    }, [service, id, viewCode])


    if (responses === null) {
        return <Stack alignItems="center">
            <CircularProgress />
        </Stack>
    }

    const preSign = async (responseId: string) => {

        setPreSigningError(null)
        setPreSigningResponseId(responseId)

        try {
            await service.preSignCreditResponse(id, responseId, viewCode)
            refreshData()
            setSignSuccess()
        } catch (e: any) {
            setPreSigningError(getErrorText(e.response.data.errorCode))
        }

        setPreSigningResponseId(null)
    }

    return <>
        {preSigningError && <Alert severity="error">{preSigningError}</Alert>}
        <Paper sx={{ p: "20px" }}>
            <Stack gap={1}>
                <h2>Pasiūlymai</h2>
                <Table>
                    <TableBody>
                        {responses.length > 0 && <TableRow>
                            <TableCell>
                                Kreditorius
                            </TableCell>
                            <TableCell>
                                Pasiūlymas
                            </TableCell>
                            <TableCell>
                                Veiksmai
                            </TableCell>
                        </TableRow>}
                        {responses.length > 0 ? responses.map(response =>
                            <TableRow>
                                <TableCell>
                                    {response.creditor}
                                </TableCell>
                                <TableCell>
                                    {response.status !== CreditorResponseStatus.Declined && <>
                                        <Typography>Sutarties mokestis: {response.contractPrice} €</Typography>
                                        <Typography>Metinės palūkanos: {response.yearInterest} %</Typography>
                                    </>}
                                </TableCell>
                                <TableCell>
                                    {!preSigningResponseId && response.status === CreditorResponseStatus.Accepted && <Button variant="contained" onClick={() => preSign(response.id)}>Sudaryti sutartį</Button>}
                                    {response.status === CreditorResponseStatus.Declined && <Chip color="error" label="Atmesta"></Chip>}
                                </TableCell>
                            </TableRow>
                        ) :
                            <TableRow>
                                <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                                    Kreditoriai kol kas nepateikė jokių pasiūlymų - patikrinkite vėliau
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Stack>
        </Paper>
    </>
}

export default RequestResponses