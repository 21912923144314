import { TextField, TextFieldProps } from "@mui/material"
import { FC } from "react"


type Props = Omit<TextFieldProps, 'onChange'> & {
    isFloat: boolean,
    onChange: (value: string) => void
}

const NumberField: FC<Props> = ({ isFloat, onChange, ...props }) => {

    const regex = isFloat ? /^[0-9]+([.,][0-9]{0,2})?$/ : /^[0-9]+$/

    const validate = (value: string): boolean => {
        if (value === "") {
            return true
        }
        return regex.test(value)
    }
    const filter = (value: string): string => {
        return value.replace(",", ".")
    }



    return <TextField onChange={e => {
        if (validate(e.target.value)) {
            onChange(filter(e.target.value))
        }
    }} {...props}></TextField>
}

export default NumberField