import { Stack } from "@mui/system"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import MenuItem from "./MenuItem/MenuItem"

export type MenuItemData = {
    path: string,
    name: string,
}

const Menu: FC<{ menuItems: MenuItemData[] }> = ({ menuItems }) => {
    const location = useLocation()
    const selected = menuItems.find(item => item.path === location.pathname)

    return <Stack direction={"row"}>
        {menuItems.map(item => <MenuItem key={item.path} path={item.path} title={item.name} selected={item === selected} />)}
    </Stack>
}


export default Menu