import { UserDataContext } from "libs/auth/providers"
import { useMemo, useContext } from "react"
import { PostsApiService } from "./api"

export const usePostsService = (): PostsApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new PostsApiService(authorizedAxios)
    }, [authorizedAxios])
}