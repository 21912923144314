export type Claims = {
    id: string
    firstName: string
    lastName: string
    email: string,
    type: UserType
}

export type RegisterForm = {
    email: string,
    firstName: string,
    lastName: string,
    password: string
}

export enum UserType {
    Super = "super",
    Admin = "admin",
    Creditor = "creditor",
    Simple = "simple",
}
export type User = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
}