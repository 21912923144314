import { Alert, CircularProgress, Divider, FormControl, FormControlLabel, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CreditorResponseStatus, FullCreditRequest } from "libs/credit/models"
import LoadingButton from "@mui/lab/LoadingButton"
import { getErrorText } from "api/errors"
import { useCreditService } from "libs/credit/hooks"
import CreditRequestInfoBlock from "components/CreditRequestInfoBlock"


type CreditorResponseForm = {
    id: string | null,
    declineReason: string,
    yearInterest: string,
    contractPrice: string,
    status: CreditorResponseStatus | null,
}



const AdminRequestEdit: FC = () => {
    const { id } = useParams()

    const [data, setData] = useState<FullCreditRequest | null>(null)
    const [status, setStatus] = useState<CreditorResponseStatus | null>(null)
    const [formData, setFormData] = useState<CreditorResponseForm>({ id: null, declineReason: "", yearInterest: "", contractPrice: "", status: null })

    const [formError, setFormError] = useState<string>("")
    const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false)
    const [isSigning, setIsSigning] = useState<boolean>(false)
    const [signError, setSignError] = useState<string | null>(null)

    const service = useCreditService()


    const fetchRequestData = useCallback(async () => {
        if (!id) return
        try {
            const data = await service.getCreditRequest(id, null)
            setData(data)
        } catch (e) {
            //TODO error
        }

    }, [id, service])

    useEffect(() => {
        fetchRequestData()
    }, [fetchRequestData])

    const fetchCreditorResponse = useCallback(async () => {
        if (!id) return
        try {
            const data = await service.getCreditorResponse(id)
            if (data) {
                setFormData({
                    id: data.id,
                    declineReason: data.declineReason ? data.declineReason : "",
                    yearInterest: data.yearInterest ? data.yearInterest.toString() : "",
                    contractPrice: data.contractPrice ? data.contractPrice.toString() : "",
                    status: data.status,
                })
                setStatus(data.status)
            }
        } catch (e) {
            //TODO error
        }
    }, [id, service])

    useEffect(() => {
        fetchCreditorResponse()
    }, [fetchCreditorResponse])


    const submitResponse = async (id: string) => {
        if (!formData.status) {
            return
        }
        setFormError("")
        setFormSubmitting(true)

        try {
            const data = await service.updateCreditorResponse(id, {
                status: formData.status,
                yearInterest: formData.status === CreditorResponseStatus.Accepted ? Number(formData.yearInterest) : null,
                contractPrice: formData.status === CreditorResponseStatus.Accepted ? Number(formData.contractPrice) : null,
                declineReason: formData.status === CreditorResponseStatus.Declined ? formData.declineReason : null,
            })
            setFormData({
                id: data.id,
                declineReason: data.declineReason ? data.declineReason : "",
                yearInterest: data.yearInterest ? data.yearInterest.toString() : "",
                contractPrice: data.contractPrice ? data.contractPrice.toString() : "",
                status: data.status,
            })
            setStatus(formData.status)
        } catch (e: any) {
            const error = e.response.data.errorCode
            setFormError(getErrorText(error))
        }

        setFormSubmitting(false)
    }

    if (!data) {
        return <Stack sx={{ width: "100%" }} alignItems="center">
            <CircularProgress />
        </Stack>
    }

    const statusToText = (status: CreditorResponseStatus | null): string => {
        if (!status) {
            return "Laukia pasiūlymo"
        }
        switch (status) {
            case CreditorResponseStatus.Accepted:
                return "Pasiūlymas pateiktas"
            case CreditorResponseStatus.Declined:
                return "Atmesta"
            case CreditorResponseStatus.PreSigned:
                return "Sudaroma sutartis"
            case CreditorResponseStatus.Signed:
                return "Pasirašyta sutartis"
        }
    }

    const statusColor = (status: CreditorResponseStatus | null): string => {//TODO colors
        if (!status) {
            return "#000000"
        }
        switch (status) {
            case CreditorResponseStatus.Accepted:
                return "#34a18e"
            case CreditorResponseStatus.Declined:
                return "#af0000"
            case CreditorResponseStatus.PreSigned:
                return "#1673ff"
            case CreditorResponseStatus.Signed:
                return "#1673ff"
        }
    }

    const sign = async (requestId: string, responseId: string) => {
        setSignError(null)
        setIsSigning(true)
        try {
            await service.signCreditResponse(requestId, responseId)
            setFormData({ ...formData, status: CreditorResponseStatus.Signed })
            setStatus(CreditorResponseStatus.Signed)
        } catch (e: any) {
            const error = e.response.data.errorCode
            setSignError(getErrorText(error))
        }
        setIsSigning(false)
    }

    const showPreSigned = formData.status === CreditorResponseStatus.PreSigned
    const showSigned = formData.status === CreditorResponseStatus.Signed
    const showEditForm = !formData.status || [CreditorResponseStatus.Accepted, CreditorResponseStatus.Declined].includes(formData.status)

    return <Stack alignItems="center">
        <Stack direction="row" alignItems={"center"} sx={{ width: "1232px" }}>
            <h2 style={{ flex: 1 }}>{`Paraiška #${data.number}`}</h2>
            <Stack direction="row" gap={1}>
                <Typography>Būklė:</Typography>
                <Typography color={statusColor(status)}>{statusToText(status)}</Typography>
            </Stack>
        </Stack>
        <Stack direction="row" gap={4}>
            <CreditRequestInfoBlock data={data} />
            <Stack>
                <h4>Administravimas</h4>
                <Paper sx={{ p: "20px", width: "600px" }}>
                    {showPreSigned && <Stack gap={2}>
                        <Typography>Vartotojui tinka sutarti su sąlygomis:</Typography>
                        <Stack direction="row">
                            <Typography sx={{ flex: 1 }}>Metinės palūkanos</Typography>
                            <Typography sx={{ flex: 1 }}>{formData.yearInterest} %</Typography>
                        </Stack>
                        <Stack direction="row">
                            <Typography sx={{ flex: 1 }}>Sutarties mokestis</Typography>
                            <Typography sx={{ flex: 1 }}>{formData.contractPrice} €</Typography>
                        </Stack>
                        {signError && <Alert severity="error">{signError}</Alert>}
                        <LoadingButton loading={isSigning} onClick={() => formData.id && sign(data.id, formData.id)} variant="contained">Pažymėti kaip pasirašytą</LoadingButton>
                    </Stack>}
                    {showSigned && <Stack gap={2}>
                        <Typography>Pasirašyta sutartis su sąlygomis:</Typography>
                        <Stack direction="row">
                            <Typography sx={{ flex: 1 }}>Metinės palūkanos</Typography>
                            <Typography sx={{ flex: 1 }}>{formData.yearInterest} %</Typography>
                        </Stack>
                        <Stack direction="row">
                            <Typography sx={{ flex: 1 }}>Sutarties mokestis</Typography>
                            <Typography sx={{ flex: 1 }}>{formData.contractPrice} €</Typography>
                        </Stack>
                    </Stack>}


                    {showEditForm && <Stack gap={2}>
                        <FormControl>
                            <Typography>Atsakymas</Typography>
                            <RadioGroup
                                value={formData.status}
                                onChange={e => setFormData({ ...formData, status: e.target.value as CreditorResponseStatus })}
                            >
                                <Stack direction="row" gap={2}>
                                    <FormControlLabel value={CreditorResponseStatus.Accepted} control={<Radio />} label="Teikti pasiūlymą" />
                                    <FormControlLabel value={CreditorResponseStatus.Declined} control={<Radio color="error" />} label="Atmesti" />
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        {formData.status && <>
                            <Divider />
                            {formData.status === CreditorResponseStatus.Accepted && <>
                                <TextField
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                    label="Metinės palūkanos"
                                    value={formData.yearInterest}
                                    onChange={e => setFormData({ ...formData, yearInterest: e.target.value })}
                                />
                                <TextField
                                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                                    label="Sutarties mokestis"
                                    value={formData.contractPrice}
                                    onChange={e => setFormData({ ...formData, contractPrice: e.target.value })}
                                />
                            </>}
                            {formData.status === CreditorResponseStatus.Declined && <>
                                <TextField label="Atmetimo priežastis" value={formData.declineReason} onChange={e => setFormData({ ...formData, declineReason: e.target.value })} />
                            </>}


                            {formError && <Alert severity="error">{formError}</Alert>}
                            <LoadingButton variant="contained" loading={isFormSubmitting} onClick={() => submitResponse(data.id)}>Saugoti</LoadingButton>
                        </>}
                    </Stack>}

                </Paper>
            </Stack>
        </Stack>
    </Stack>
}


export default AdminRequestEdit