import { UserDataContext } from "libs/auth/providers"
import { useMemo, useContext } from "react"
import { UploadApiService } from "./api"

export const useUploadService = (): UploadApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new UploadApiService(authorizedAxios)
    }, [authorizedAxios])
}