import { createTheme, ThemeProvider } from "@mui/material"
import { FC } from "react"

const theme = createTheme({
    palette: {
        primary: {
            main: "#34a18e"
        },
        info: {
            main: "#000000"
        },
        background: {
            default: "#E5F8FC"
        }
    }
});

const ThemeWrapper: FC<{ children: React.ReactNode | React.ReactNode[] }> = ({ children }) => {

    return <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>

}


export default ThemeWrapper