import { Dialog, DialogContent, DialogTitle, Button, Typography, Stack } from "@mui/material"
import { UserDataContext } from "libs/auth/providers"
import { FC, useContext } from "react"
import { Link } from "react-router-dom"


const SuccessDialog: FC<{ open: boolean, setOpen: (open: boolean) => void }> = ({ open, setOpen }) => {
    const { userData } = useContext(UserDataContext)
    return <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Sveikiname!</DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
            <Stack gap={2}>
                <Typography>Jūsų paraiška sėkmingai pateikta.</Typography>
                {userData ? <>
                    <Typography>Informaciją apie pateiktas paraiškas rasite vartotojo meniu viršuje arba paspaudę mygtuką apačioje.</Typography>
                    <Stack direction="row" gap={1}>
                        <Button variant="contained" component={Link} to={"/mano/paraiskos"}>Mano paraiškos</Button>
                        <Button variant="outlined" onClick={() => setOpen(false)}>Uždaryti</Button>
                    </Stack>
                </>
                    :
                    <>
                        <Typography>Informaciją apie paraišką išsiuntemė paraiškoje nurodytu elektroniniu paštu</Typography>
                        <Button variant="outlined" onClick={() => setOpen(false)}>Uždaryti</Button>
                    </>
                }
            </Stack>
        </DialogContent>
    </Dialog>
}

export default SuccessDialog