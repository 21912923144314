import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Theme, Typography, useMediaQuery } from "@mui/material"
import { FC, useState } from "react"
import LoginContent from "./LoginContent"
import PasswordResetInit from "./PasswordResetInit"
import RegisterContent from "./RegisterContent"
import CloseIcon from "@mui/icons-material/Close";


enum Screens {
    Login = "login",
    Register = "register",
    PasswordReset = "password-reset"
}


const AuthDialog: FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {
    const [screen, setScreen] = useState<Screens>(Screens.Login)

    const Content = (screen: Screens): React.ReactElement => {
        switch (screen) {
            case Screens.Login:
                return <LoginContent onClose={onClose} openReset={() => setScreen(Screens.PasswordReset)} openRegister={() => setScreen(Screens.Register)} />
            case Screens.Register:
                return <RegisterContent onClose={onClose} openLogin={() => setScreen(Screens.Login)} />
            case Screens.PasswordReset:
                return <PasswordResetInit onClose={onClose} openLogin={() => setScreen(Screens.Login)} />
        }
    }
    const Title = (screen: Screens): string => {
        switch (screen) {
            case Screens.Login:
                return "Prisijungimas"
            case Screens.Register:
                return "Registracija"
            case Screens.PasswordReset:
                return "Slaptažodžio atstatymas"
        }
    }
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
        <DialogTitle>
            <Stack direction="row" sx={{ width: "100%" }} justifyContent="stretch" alignItems={"center"}>
                <Typography sx={{flex:1, textAlign:"center"}} fontSize="inherit">{Title(screen)}</Typography>
                {isMobile && <IconButton onClick={onClose} sx={{ mb: 2 }}>
                    <CloseIcon />
                </IconButton>}
            </Stack>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "350px" }}>
            {Content(screen)}
        </DialogContent>
    </Dialog>
}

export default AuthDialog