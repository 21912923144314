import React, { createContext, FC, useCallback, useEffect, useState } from "react";
import { useSettingsService } from "./hooks";
import { Setting } from "./models";

type SettingsContextType = {
    getSetting: (field: string) => string | null,
    settings: Setting[],
    updateSettings: (settings: Setting[]) => void,
    loaded: boolean,
}


export const SettingCompanyPhone = "companyPhone"
export const SettingCompanyEmail = "companyEmail"
export const SettingCompanyName = "companyName"
export const SettingCompanyAddress = "companyAddress"


export const SettingsContext = createContext<SettingsContextType>({ loaded: false, settings: [], getSetting: () => null, updateSettings: () => { } })

export const SettingsProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {

    const settingsService = useSettingsService()
    const [loaded, setLoaded] = useState<boolean>(false)

    const [settings, setSettings] = useState<Setting[]>([])

    const getSetting = useCallback((field: string) => {
        const result = settings.find(item => item.field === field)
        return result ? result.value : null
    }, [settings])

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoaded(false)
                setSettings(await settingsService.get())
                setLoaded(true)
            } catch (e) {
                console.log(e)
            }
        }
        fetchSettings()
    }, [settingsService])


    return <SettingsContext.Provider value={{ loaded: loaded, settings: settings, getSetting: getSetting, updateSettings: (settings: Setting[]) => setSettings(settings) }}>
        {children}
    </SettingsContext.Provider>
}