import { Response } from "api/models";
import { AxiosService } from "libs/utils/AxiosService";
import { Upload } from "./models";

export class UploadApiService extends AxiosService {
    host: string = "/api/upload";


    uploadFile(file: File): Promise<Upload> {
        const formData = new FormData();
        formData.append("file", file);
        return this.axios.post<Response<Upload>>(`${this.host}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(x => x.data.data)
    }

}