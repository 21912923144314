import { UserDataContext } from "libs/auth/providers"
import { Page } from "libs/pages/models"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { MenuApiService } from "./api"


export const useMenuService = (): MenuApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new MenuApiService(authorizedAxios)
    }, [authorizedAxios])
}


export const useMenu = (key:string) => {
    const menuService = useMenuService()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [menuItems, setMenuItems] = useState<Page[]>([])


    const fetchPages = useCallback(async () => {
        setIsLoading(true)

        setMenuItems(await menuService.getMenu(key))

        setIsLoading(false)
    }, [menuService, key])


    useEffect(() => {
        fetchPages()
    }, [fetchPages])


    return { menuItems, isLoading }

}

