import { Response } from "api/models";
import { User } from "libs/auth/models";
import { AxiosService } from "libs/utils/AxiosService";
import { Creditor, CreditorForm, CreditorResponse, CreditorResponseForm, CreditRequestForm, FullCreditRequest, PaymentRequestData, RequestStatusEnum } from "./models";


export class CreditorsApiService extends AxiosService {
    host: string = "/api/admin/creditors";

    create(form: CreditorForm): Promise<Creditor> {
        return this.axios.post<Response<Creditor>>(`${this.host}`, form).then(x => x.data.data)
    }

    edit(id: string, form: CreditorForm): Promise<Creditor> {
        return this.axios.put<Response<Creditor>>(`${this.host}/${id}`, form).then(x => x.data.data)
    }

    get(id: string): Promise<Creditor> {
        return this.axios.get<Response<Creditor>>(`${this.host}/${id}`).then(x => x.data.data)
    }

    getUsers(id: string): Promise<User[]> {
        return this.axios.get<Response<User[]>>(`${this.host}/${id}/users`).then(x => x.data.data)
    }

    addUser(id: string, email: string): Promise<void> {
        return this.axios.post(`${this.host}/${id}/users/${email}`)
    }

    deleteUser(id: string, userId: string): Promise<void> {
        return this.axios.delete(`${this.host}/${id}/users/${userId}`)
    }

    getCount(): Promise<number> {
        return this.axios.get<Response<number>>(`${this.host}/count`).then(x => x.data.data)
    }
}


export class CreditApiService extends AxiosService {
    endpoint: string = "/api/credit";
    adminEndpoint: string = "/api/admin";
    creditorEndpoint: string = "/api/creditor-admin";

    requestCredit(form: CreditRequestForm): Promise<void> {
        return this.axios.post(`${this.endpoint}/requests`, form)
    }

    generatePaymentUrl(id: string, requestData: PaymentRequestData): Promise<string> {
        return this.axios.post<Response<string>>(`${this.endpoint}/requests/${id}/payment-url`, requestData).then(x => x.data.data)
    }

    preSignCreditResponse(requestId: string, responseId: string, code: string | null): Promise<void> {
        return this.axios.post(`${this.endpoint}/requests/${requestId}/responses/${responseId}/pre-sign${code ? `?code=${code}` : ""}`)
    }

    signCreditResponse(requestId: string, responseId: string): Promise<void> {
        return this.axios.post(`${this.endpoint}/requests/${requestId}/responses/${responseId}/sign`)
    }

    getCreditRequest(id: string, code: string | null): Promise<FullCreditRequest | null> {
        return this.axios.get<Response<FullCreditRequest | null>>(`${this.endpoint}/requests/${id}${code ? `?code=${code}` : ""}`).then(x => x.data.data)
    }

    getRequestResponses(id: string, code: string | null): Promise<CreditorResponse[]> {
        return this.axios.get<Response<CreditorResponse[]>>(`${this.endpoint}/requests/${id}/responses${code ? `?code=${code}` : ""}`).then(x => x.data.data)
    }


    changeCreditRequestStatus(id: string, status: RequestStatusEnum): Promise<void> {
        return this.axios.post(`${this.adminEndpoint}/requests/${id}/status/${status}`)
    }

    assignContractFile(id: string, fileId: string): Promise<void> {
        return this.axios.post(`${this.adminEndpoint}/requests/${id}/contract/${fileId}`)
    }

    getContractDownloadUrl(id: string, code: string|null): string {
        return `${this.adminEndpoint}/requests/${id}/contract${code ? `?code=${code}` : ""}`
    }

    updateCreditorResponse(requestId: string, form: CreditorResponseForm): Promise<CreditorResponse> {
        return this.axios.post<Response<CreditorResponse>>(`${this.creditorEndpoint}/credit-requests/${requestId}/response`, form).then(x => x.data.data)
    }

    getCreditorResponse(requestId: string): Promise<CreditorResponse | null> {
        return this.axios.get<Response<CreditorResponse | null>>(`${this.creditorEndpoint}/credit-requests/${requestId}/response`).then(x => x.data.data)
    }
}
