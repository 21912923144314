import { AxiosService } from "libs/utils/AxiosService";
import { Setting, SettingsForm } from "libs/settings/models";
import { Response } from "api/models";

export class SettingsApiService extends AxiosService {
    host: string = "/api/settings";

    get(): Promise<Setting[]> {
        return this.axios.get<Response<Setting[]>>(this.host).then(x => x.data.data)
    }

    update(form: SettingsForm): Promise<void> {
        return this.axios.post(this.host, form)
    }
}