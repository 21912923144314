import { UserDataContext } from "libs/auth/providers"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { PagesApiService } from "./api"
import { Page } from "./models"


export const usePagesService = (): PagesApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new PagesApiService(authorizedAxios)
    }, [authorizedAxios])
}


export const usePages = () => {
    const pageService = usePagesService()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [pages, setPages] = useState<Page[]>([])


    const fetchPages = useCallback(async () => {
        setIsLoading(true)

        setPages(await pageService.getAll())

        setIsLoading(false)
    }, [pageService])


    useEffect(() => {
        fetchPages()
    }, [fetchPages])


    return { pages, isLoading }
}