import { Stack, CircularProgress, Alert } from "@mui/material"
import { getErrorText } from "api/errors"
import { usePagesService } from "libs/pages/hooks"
import { Page } from "libs/pages/models"
import { useEffect, useState } from "react"
import { useParams } from "react-router"

const Posts = () => {
    const service = usePagesService()

    const { url } = useParams()

    const [pageData, setPageData] = useState<Page | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    useEffect(() => {
        const fetch = async (url: string) => {
            setIsLoading(true)
            setError(null)
            try {
                const result = await service.getByUrl(url)
                setPageData(result)
            } catch (e: any) {
                //TODO error
                setError(getErrorText(e.response.data.errorCode))
            }
            setIsLoading(false)
        }
        url && fetch(url)
    }, [url, service])

    if (isLoading) {
        return <Stack alignItems={"center"}><CircularProgress /></Stack>
    }
    if (error || !pageData) {
        return <Stack alignItems={"center"}><Alert severity="error">{error ? error : "Puslapis nerastas"}</Alert></Stack>
    }

    return <Stack>
        <h2>
            {pageData.title}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: pageData.content }}></div>
    </Stack>
}


export default Posts