import { Column, Datatable } from "@brunas/react-mui-datatable"
import { Button, IconButton, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import { PageDatatableItem } from "libs/pages/models"
import { useDatatableWithApi } from "libs/datatable/hooks";

const Posts = () => {
    const data = useDatatableWithApi<PageDatatableItem, null>({ endpoint: "/admin/pages/datatable", config: null })

    const navigate = useNavigate()

    const columns = useMemo<Column<PageDatatableItem>[]>(() => [
        {
            value: (item: PageDatatableItem) => item.title,
            header: "Pavadinimas"
        },
        {
            value: (item: PageDatatableItem) => item.url,
            header: "Adresas"
        },
        {
            header: "Veiksmai",
            getActions: (params) => {
                return [
                    <Button onClick={() => navigate(`/admin/pages/${params.row.id}`)}>Redaguoti</Button>
                ]
            }
        }
    ], [navigate])


    return <Stack gap={2} sx={{ width: "100%" }}>
        <Stack direction="row" gap={1}>
            <Typography sx={{ fontSize: "35px" }}>Puslapiai</Typography>
            <IconButton onClick={() => navigate("/admin/pages/create")} color="primary"><AddIcon fontSize="large" /></IconButton>
        </Stack>
        <Datatable {...data} globalSearchEnabled={true} columns={columns} />
    </Stack>
}

export default Posts