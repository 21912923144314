import { CircularProgress, Stack, Theme, useMediaQuery } from "@mui/material"
import { useMenu } from "libs/menu/hooks"
import { FooterMenu } from "libs/settings/models"
import { SettingCompanyAddress, SettingCompanyEmail, SettingCompanyName, SettingCompanyPhone, SettingsContext } from "libs/settings/providers"
import { useContext } from "react"
import { Link } from "react-router-dom"
import FooterBlock from "./FooterBlock"



const Footer = () => { //TODO blocks
    const { getSetting } = useContext(SettingsContext)
    const { menuItems, isLoading } = useMenu(FooterMenu)

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const phone = getSetting(SettingCompanyPhone)
    const email = getSetting(SettingCompanyEmail)
    const address = getSetting(SettingCompanyAddress)
    const name = getSetting(SettingCompanyName)

    return <Stack direction={isMobile ? "column" : "row"} alignItems={isMobile ? "center" : "initial"} gap={isMobile ? 2 : 0} sx={{ justifyContent: "space-between" }}>
        <FooterBlock title="Apie Vienas Kreditas">
        1kreditas – tai UAB „Derybininkai.lt” sukurtas įrankis, kuris leis palyginti skirtingų kredito
bendrovių pasiūlymus ir sudaryti vartojimo kredito sutartį. Vos kelių mygtukų paspaudimu

sutrumpinsite ilgą ir varginantį procesą kreipiantis į skirtingus kreditus, nes geriausią ir patogiausią
sprendimą paskoloms nuo 50 iki 15 000 eurų gausite net neišėję iš namų
</FooterBlock>
        <FooterBlock title="Kontaktai">
            {name && <>{name}<br /></>}
            {address && <>{address}<br /></>}
            {phone && <>{phone}<br /></>}
            {email && <>{email}<br /></>}
        </FooterBlock>
        {isLoading ?
            <CircularProgress />
            :
            <FooterBlock title="Informacija">
                {menuItems.map(item => <Link key={item.id} style={{ color: "#000" }} to={`/puslapiai/${item.url}`}>{item.title}</Link>)}
            </FooterBlock>
        }

    </Stack>
}


export default Footer