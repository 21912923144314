import { Stack, Button, Typography, CardContent, Card, CircularProgress } from "@mui/material"
import { usePostsService } from "libs/posts/hooks"
import { Post } from "libs/posts/models"
import { useEffect, useState } from "react"


type PostsData = { [propType: number]: Post[] }

const Posts = () => {
    const [page, setPage] = useState<number>(1)

    const service = usePostsService()

    const [posts, setPosts] = useState<PostsData>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [pagesCount, setPagesCount] = useState<number>(1)


    useEffect(() => {
        const fetchPagesCount = async () => {
            setPagesCount(await service.getPagesCount())
        }
        fetchPagesCount()

    }, [service])


    useEffect(() => {
        const fetch = async (page: number) => {
            setIsLoading(true)
            try {
                const result = await service.getList(page)
                setPosts(current => ({ ...current, [page]: result }))
            } catch (e: any) {
                //TODO error
            }
            setIsLoading(false)
        }
        fetch(page)
    }, [page, service])

    return <>
        <Stack alignItems={"center"} gap={2}>
            <Stack sx={{ width: "700px", maxWidth:"100%" }} direction="column" gap={3}>
                {Object.values(posts).map(list => list.map(post => <Card sx={{ maxWidth: 700 }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {post.title}
                        </Typography>
                        <Typography color="text.secondary">
                            {post.date.format("YYYY-MM-DD")}
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                    </CardContent>
                </Card>))}
            </Stack>
            {isLoading && <CircularProgress />}
            {!isLoading && pagesCount > page && <Button variant="outlined" onClick={() => setPage(page + 1)}>Daugiau naujienių</Button>}
        </Stack>
    </>
}


export default Posts