
import ReactGA from "react-ga4";

type Params = { [propKey: string]: string }

const useAnalyticsEvents = () => {
    const sendEvent = async (event: AnalyticsEvent, params?: Params) => {
        ReactGA.event(event, params);
    }
    return { sendEvent };
}
export default useAnalyticsEvents;


export enum AnalyticsEvent{
    FormFocus = "form_focused",
    FormStep1 = "form_step1",
    FormSubmit = "form_submitted",
}