import { Divider, Paper, Stack, Typography } from "@mui/material"
import { FullCreditRequest, RequestTypeEnum, RequestTypeList, CreditRequestData, BusinessRequestData, CarLeasingRequestData, DocumentTypes, IncomeType } from "libs/credit/models"
import { FC } from "react"

const InfoItem: FC<{ header: string, data: string }> = ({ header, data }) => {
    return <Stack direction="row">
        <Typography sx={{ flex: 1 }}>{header}</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>{data}</Typography>
    </Stack>
}

const typeToText = (type: RequestTypeEnum): string => {

    const item = RequestTypeList.find(item => item.type === type)
    return item ? item.name : RequestTypeList[0].name
}

const CreditRequestInfoBlock: FC<{ data: FullCreditRequest }> = ({ data }) => {


    const carData = data.data as CarLeasingRequestData
    const businessData = data.data as BusinessRequestData
    const creditData = data.data as CreditRequestData

    return <Stack>
        <h4>Paraiškos duomenys</h4>
        <Paper sx={{ p: "20px", width: "600px" }}>
            <Stack gap={2}>
                <InfoItem header="Paskolos tipas" data={typeToText(data.type)} />
                <InfoItem header="Paskolos suma" data={`${data.amount} €`} />
                {data.type !== RequestTypeEnum.Business && <InfoItem header="Terminas" data={`${data.periodMonths} mėn.`} />}
                {data.type !== RequestTypeEnum.Business ? <>
                    {data.type === RequestTypeEnum.CarLeasing && <>
                        <Typography fontWeight="bold">Automobilio duomenys</Typography>
                        <Divider />
                        <InfoItem header="Markė, modelis" data={carData.carBrand} />
                        <InfoItem header="Registracijos metai" data={carData.carYear} />
                    </>}
                    <Typography fontWeight="bold">Asmeniniai duomenys</Typography>
                    <Divider />
                    <InfoItem header="Vardas, pavardė" data={data.creator} />
                    <InfoItem header="Asmens kodas" data={creditData.personCode} />
                    <InfoItem header="Yra sutuoktinis?" data={creditData.married ? "Taip" : "Ne"} />
                    <InfoItem header="Telefonas" data={data.phone} />
                    <InfoItem header="El. paštas" data={data.email} />
                    <InfoItem header="Dokumento tipas" data={DocumentTypes.find(item => item.value === creditData.documentType)?.label || "Nenurodyta"} />
                    <Typography fontWeight="bold">Pajamos</Typography>
                    <Divider />
                    <InfoItem header="Pagrindinių pajamų rūšis" data={IncomeType.find(item => item.value === creditData.incomeType)?.label || "Nenurodyta"} />
                    <InfoItem header="Mėnėsio pajamos ”į rankas”" data={creditData.income.toString()} />
                    <InfoItem header="Kitos pajamos" data={creditData.otherIncome} />
                    <Typography fontWeight="bold">Įsipareigojimai</Typography>
                    <Divider />
                    <InfoItem header="Vartojimo paskolų įmokos (Eur/mėn.)" data={creditData.monthlyCreditOutcome.toString()} />
                    <InfoItem header="Kiti finansiniai įsipareigojimai (Eur/mėn.)" data={creditData.monthlyOtherOutcome.toString()} />
                </>
                    :
                    <>
                        <Typography fontWeight="bold">Pareiškėjo duomenys</Typography>
                        <Divider />
                        <InfoItem header="Įmonė" data={businessData.companyName} />
                        <InfoItem header="Telefonas" data={data.phone} />
                        <InfoItem header="El. paštas" data={data.email} />
                    </>
                }
            </Stack>
        </Paper>
    </Stack>
}


export default CreditRequestInfoBlock