import { Typography, Stack } from "@mui/material"
import { FC } from "react"


const FieldWithLabel: FC<{ children?: React.ReactNode, label: string }> = ({ children, label }) => {
    return <Stack sx={{ width: "100%" }} gap={"2px"}>
        <Typography>{label}</Typography>
        {children}
    </Stack>
}

export default FieldWithLabel
