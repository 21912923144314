import { Column, Datatable, FilterValueUpdateProps, SelectFilter, Sorting, SortingDirectionEnum, SelectFilterValueType } from "@brunas/react-mui-datatable"
import { Chip, Stack, Typography } from "@mui/material"
import { useCreditorsService } from "libs/credit/hooks"
import { RequestDatatableItem, RequestStatusEnum, RequestTypeList } from "libs/credit/models"
import { useDatatableWithApi } from "libs/datatable/hooks"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

const defaultSorting: Sorting[] = [
    {
        field: "date",
        direction: SortingDirectionEnum.Descending
    }
]

const getStatusName = (status: RequestStatusEnum): string => {
    switch (status) {
        case RequestStatusEnum.New:
            return "Užpildyta"
        case RequestStatusEnum.InProgress:
            return "Perduota kreditoriams"
        case RequestStatusEnum.Closed:
            return "Uždaryta"
    }
}

const StatusBadge: FC<{ item: RequestDatatableItem }> = ({ item }) => {
    switch (item.status) {
        case RequestStatusEnum.New:
            return <Chip color="info" label={getStatusName(item.status)} />
        case RequestStatusEnum.InProgress:
            return <Chip color="primary" label={getStatusName(item.status)} />
        case RequestStatusEnum.Closed:
            return <Chip color={item.signed ? "success" : "error"} label={getStatusName(item.status)} />
    }
}

const CreditorResponses = (item: RequestDatatableItem, creditorCount: number | null): string => {
    if (item.status === RequestStatusEnum.New) {
        return "--"
    }

    return `${item.responsesCount} iš ${creditorCount ? creditorCount : "?"}`
}


const AdminRequestList = () => {
    const data = useDatatableWithApi<RequestDatatableItem, null>({ endpoint: "/admin/requests/datatable", config: null, defaultSorting: defaultSorting })

    const [creditorCount, setCreditorCount] = useState<number | null>(null)


    const service = useCreditorsService()

    useEffect(() => {
        const fetchCreditorCount = async () => {
            setCreditorCount(await service.getCount())
        }
        fetchCreditorCount()
    }, [service])

    const navigate = useNavigate()

    const columns = useMemo<Column<RequestDatatableItem>[]>(() => [
        {
            value: (item: RequestDatatableItem) => `#${item.number}`,
            header: "ID"
        },
        {
            value: (item: RequestDatatableItem) => RequestTypeList.find(x => x.type === item.type)?.name,
            header: "Paskolos tipas"
        },
        {
            value: (item: RequestDatatableItem) => item.date.format("YYYY-MM-DD"),
            header: "Data",
            sortingField: "date",
        },
        {
            value: (item: RequestDatatableItem) => `${item.amount} €`,
            header: "Paskolos suma",
            sortingField: "amount",
        },
        {
            value: (item: RequestDatatableItem) => `${item.periodMonths} mėn.`,
            header: "Periodas",
            sortingField: "periodMonths",
        },
        {
            value: (item: RequestDatatableItem) => <StatusBadge item={item} />,
            header: "Būklė"
        },
        {
            value: (item: RequestDatatableItem) => CreditorResponses(item, creditorCount),
            header: "Kreditorių pasiūlymai"
        }
    ], [creditorCount])

    const filters = useCallback((props: FilterValueUpdateProps) => [
        <SelectFilter key="status" {...props} field="status" label="Būklė" filterValueType={SelectFilterValueType.String} values={Object.values(RequestStatusEnum).map(item => ({
            value: item,
            label: getStatusName(item)
        }))} />
    ], [])


    return <Stack gap={2} sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "35px" }}>Paraiškos</Typography>
        <Datatable onRowClick={row => navigate(`/admin/credit-requests/${row.id}`)} {...data} globalSearchEnabled={false} columns={columns} filters={filters} />
    </Stack>
}

export default AdminRequestList