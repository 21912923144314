import { Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/lt';
import Home from 'views/Home';
import Layout from 'components/Layout';
import { RequestTypeList } from 'libs/credit/models';
import MyRequests from 'views/Me/MyRequests';
import AdminLayout from 'components/AdminLayout';
import AdminRequestList from 'views/Admin/AdminRequestList';
import CreditorList from 'views/Admin/CreditorList';
import CreditorEdit from 'views/Admin/CreditorEdit';
import Posts from 'views/Posts';
import PostsAdmin from 'views/Admin/Posts';
import PagesAdmin from 'views/Admin/Pages';
import PostEdit from 'views/Admin/PostEdit';
import Settings from 'views/Admin/Settings';
import AdminRequestEdit from 'views/Admin/AdminRequestEdit';
import CreditorRequestsList from 'views/CreditorAdmin/CreditorRequestsList';
import CreditorRequestEdit from 'views/CreditorAdmin/CreditorRequestEdit';
import ViewRequest from 'views/Me/ViewRequest';
import PageEdit from 'views/Admin/PageEdit';
import Page from 'views/Page';
import { SettingsProvider } from 'libs/settings/providers';
import { UserDataProvider } from 'libs/auth/providers';
import UserConfirm from 'views/Me/UserConfirm';
import ReactGA from "react-ga4";
import ThemeWrapper from 'components/Layout/ThemeWrapper';
import PasswordReset from 'views/PasswordReset';

const TRACKING_ID = "G-BDHTEJST2W";
ReactGA.initialize(TRACKING_ID);

const myLocale = locale

const App = () => {
  const firstForm = RequestTypeList[0]

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={myLocale}>
      <UserDataProvider>
        <SettingsProvider>
          <Routes>
            <Route path="/" element={<ThemeWrapper><Layout/></ThemeWrapper>}>
              <Route path="formos" element={<Home />}>
                {RequestTypeList.map(item => <Route key={item.type} path={item.path} element={<item.component />} />)}
              </Route>
              
              <Route path="vartotojo-patvirtinimas/:id" element={<UserConfirm />} />
              <Route path="slaptazodzio-atstatymas/:id" element={<PasswordReset />} />
              <Route path="mano/paraiskos" element={<MyRequests />} />
              <Route path="mano/paraiskos/:id" element={<ViewRequest />} />
              <Route path="naujienos" element={<Posts />} />
              <Route path="puslapiai/:url" element={<Page />} />
              <Route path="/" element={<Home />}>
                <Route index element={<firstForm.component />} />
                <Route path="*" element={<firstForm.component />} />
              </Route>
            </Route>
            <Route path="/admin" element={<AdminLayout />}>
              <Route path="*" element={<AdminRequestList />} />
              <Route index element={<AdminRequestList />} />
              <Route path="settings" element={<Settings />} />
              <Route path="credit-requests/:id" element={<AdminRequestEdit />} />
              <Route path="posts/create" element={<PostEdit />} />
              <Route path="posts/:id" element={<PostEdit />} />
              <Route path="posts" element={<PostsAdmin />} />
              <Route path="pages/create" element={<PageEdit />} />
              <Route path="pages/:id" element={<PageEdit />} />
              <Route path="pages" element={<PagesAdmin />} />
              <Route path="creditors/create" element={<CreditorEdit />} />
              <Route path="creditors/:id" element={<CreditorEdit />} />
              <Route path="creditors" element={<CreditorList />} />
            </Route>
            <Route path="/creditor-admin" element={<AdminLayout />}>
              <Route path="*" element={<CreditorRequestsList />} />
              <Route index element={<CreditorRequestsList />} />
              <Route path="credit-requests/:id" element={<CreditorRequestEdit />} />
            </Route>
          </Routes>
        </SettingsProvider>
      </UserDataProvider>
    </LocalizationProvider>
  );
};

export default App;
