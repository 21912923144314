import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, CircularProgress, Divider, Paper, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getErrorText } from "api/errors"
import { usePagesService } from "libs/pages/hooks"
import { PageForm } from "libs/pages/models"

const PageEdit: FC = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const service = usePagesService()

    const [loaded, setLoaded] = useState<boolean>(false)
    const [form, setForm] = useState<PageForm>({ url: "", title: "", content: "" })

    const [formError, setFormError] = useState<string>("")


    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        const fetch = async (id: string) => {
            try {
                const data = await service.get(id)
                setForm(data)
                setLoaded(true)
            } catch (e) {
                //TODO error
            }
        }
        id ? fetch(id) : setLoaded(true)
    }, [id, service])



    const validate = (): string | null => {
        if (!form.content || !form.title || !form.url) {
            return "Nepalikite tuščių laukelių"
        }
        return null
    }


    const submit = async () => {
        setFormError("")
        const frontError = validate()
        if (frontError) {
            setFormError(frontError)
            return
        }

        setIsSubmitting(true)
        try {
            if (id) {
                await service.edit(id, form)
            } else {
                const result = await service.create(form)
                navigate(`/admin/pages/${result.id}`)
            }
        } catch (e: any) {
            const error = e.response.data.errorCode
            setFormError(getErrorText(error))
        }
        setIsSubmitting(false)
    }


    if (!loaded) {
        return <Stack sx={{ width: "100%" }} alignItems="center">
            <CircularProgress />
        </Stack>
    }


    return <Stack alignItems="center">
        <Paper sx={{ p: "20px", width: "800px" }}>
            <h2>{id ? "Redaguoti puslapį" : "Kurti puslapį"}</h2>
            <Stack gap={2} sx={{ width: "100%" }}>
                <TextField sx={{ flex: 1 }} size="small" label="Pavadinimas" value={form.title} onChange={e => setForm({ ...form, title: e.target.value })}></TextField>
                <TextField sx={{ flex: 1 }} size="small" label="URL adresas" value={form.url} onChange={e => setForm({ ...form, url: e.target.value })}></TextField>
                <Divider />
                <Stack>
                    <ReactQuill theme="snow" value={form.content} onChange={(content: string) => setForm({ ...form, content: content })} />
                </Stack>

                {formError && <Alert severity="error">{formError}</Alert>}
                <LoadingButton variant="contained" loading={isSubmitting} onClick={submit}>Saugoti</LoadingButton>
            </Stack>
        </Paper>
    </Stack>
}


export default PageEdit