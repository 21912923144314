import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, CircularProgress, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { User } from "libs/auth/models"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorNotFound, getErrorText } from "api/errors"
import { useCreditorsService } from "libs/credit/hooks"
import { CreditorForm } from "libs/credit/models"

const CreditorEdit: FC = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const service = useCreditorsService()

    const [loaded, setLoaded] = useState<boolean>(false)
    const [form, setForm] = useState<CreditorForm>({ title: "" })


    const [isUserAdding, setIsUserAdding] = useState<boolean>(false)
    const [deletingUsers, setDeletingUsers] = useState<string[]>([])
    const [userAddEmail, setUserAddEmail] = useState<string>("")
    const [userAddError, setUserAddError] = useState<string>("")


    const [usersLoaded, setUsersLoaded] = useState<boolean>(false)
    const [users, setUsers] = useState<User[]>([])

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)


    const fetchUsers = useCallback(async (id: string) => {
        setUsersLoaded(false)
        try {
            const data = await service.getUsers(id)
            setUsers(data)
            setUsersLoaded(true)
        } catch (e) {
            //TODO error
        }
    }, [service])

    useEffect(() => {
        const fetch = async (id: string) => {
            try {
                const data = await service.get(id)
                setForm(data)
                setLoaded(true)
            } catch (e) {
                //TODO error
            }
        }
        id ? fetch(id) : setLoaded(true)
        id && fetchUsers(id)
    }, [id, service, fetchUsers])

    const addUser = async (id: string) => {
        setIsUserAdding(true)
        setUserAddError("")
        try {
            await service.addUser(id, userAddEmail)
            fetchUsers(id)
        } catch (e: any) {
            const error = e.response.data.errorCode
            if (error === ErrorNotFound) {
                setUserAddError("Vartotojas nerastas")
            } else {
                setUserAddError(getErrorText(error))
            }
        }
        setIsUserAdding(false)
    }

    const removeUser = async (id: string, userId: string) => {
        setDeletingUsers(current => [...current, userId])
        try {
            await service.deleteUser(id, userId)
            setUsers(current => current.filter(item => item.id !== userId))
        } catch (e) {
            //TODO error
        }
        setDeletingUsers(current => current.filter(item => item !== userId))
    }

    const submit = async () => {
        //TODO validate
        setIsSubmitting(true)
        try {
            if (id) {
                await service.edit(id, form)
            } else {
                const result = await service.create(form)
                navigate(`/admin/creditors/${result.id}`)
            }
        } catch (e) {
            //TODO error
        }
        setIsSubmitting(false)
    }

    if (!loaded) {
        return <Stack sx={{ width: "100%" }} alignItems="center">
            <CircularProgress />
        </Stack>
    }

    const isUserDeleting = (id: string) => {
        return deletingUsers.includes(id)
    }

    return <Stack alignItems="center">
        <Paper sx={{ p: "20px", width: "600px" }}>
            <h2>{id ? "Redaguoti kreditorių" : "Kurti kreditorių"}</h2>
            <Stack direction="row" gap={1} alignItems="center" sx={{ width: "100%" }}>
                <TextField sx={{ flex: 1 }} size="small" label="Pavadinimas" value={form.title} onChange={e => setForm({ ...form, title: e.target.value })}></TextField>
                <LoadingButton variant="contained" loading={isSubmitting} onClick={submit}>Saugoti</LoadingButton>
            </Stack>

            {id && <><Divider sx={{ pt: "30px" }} /><Stack gap={1} sx={{ pt: "20px" }}>
                <Typography fontSize={"24px"}>Kreditoriaus varototojai</Typography>
                <Stack direction="row" gap={1} alignItems="center" sx={{ width: "100%" }}>
                    <TextField label="El. pašto adresas" size="small" sx={{ flex: 1 }} value={userAddEmail} onChange={e => {
                        setUserAddEmail(e.target.value)
                        setUserAddError("")
                    }}></TextField>
                    <LoadingButton variant="contained" loading={isUserAdding} onClick={() => addUser(id)}>Pridėti vartotoją</LoadingButton>
                </Stack>
                {userAddError && <Alert severity="error">{userAddError}</Alert>}


                <List>
                    {usersLoaded ?
                        <>
                            {users.length > 0 ? users.map((user, i) => <ListItem>
                                <ListItemIcon>{i + 1}.</ListItemIcon>
                                <ListItemText>{user.firstName} {user.lastName}</ListItemText>
                                <ListItemIcon>
                                    {isUserDeleting(user.id) ? <CircularProgress /> : <IconButton onClick={() => removeUser(id, user.id)}><DeleteIcon /></IconButton>}
                                </ListItemIcon>

                            </ListItem>) : <ListItem>Kreditorius neturi vartotojų</ListItem>}
                        </>
                        :
                        <Stack alignItems={"center"}><CircularProgress /></Stack>
                    }
                </List>
            </Stack></>}
        </Paper>
    </Stack>
}


export default CreditorEdit