import { Divider, Stack, Typography, Alert, Select, MenuItem, Button } from "@mui/material"
import { UserDataContext } from "libs/auth/providers"
import { CreditRequestData, RequestTypeEnum } from "libs/credit/models"
import { useContext, useState } from "react"
import FieldWithLabel from "../FieldWithLabel"
import RulesCheckbox from "../RulesCheckbox"
import SuccessDialog from "../SuccessDialog"
import LoadingButton from '@mui/lab/LoadingButton';
import { getErrorText } from "api/errors"
import { useCreditService } from "libs/credit/hooks"
import CreditFormData, { FormData } from "../CreditFormData"
import useAnalyticsEvents, { AnalyticsEvent } from "libs/ga/hooks"
import NumberField from "components/NumberField"
import { Link } from "react-router-dom"
import isEmail from "libs/utils/isEmail"

const PeriodFields: number[] = [
    6, 9, 12, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120
]


const FormCredit = () => {
    const { userData } = useContext(UserDataContext)
    const [form, setForm] = useState<FormData>({
        amount: "10000",
        periodMonths: 12,
        email: userData ? userData.email : "",
        phone: "",
        type: RequestTypeEnum.Credit,
        data: null,
    })
    const [data, setData] = useState<CreditRequestData>({
        married: false,
        personCode: "",
        documentType: "",
        incomeType: "",
        firstName: userData ? userData.firstName : "",
        lastName: userData ? userData.lastName : "",
        income: "",
        otherIncome: "",
        monthlyCreditOutcome: "",
        monthlyOtherOutcome: "",
    })
    const { sendEvent } = useAnalyticsEvents()


    const [errorFields, setErrorFields] = useState<string[]>([])

    const [opened, setOpened] = useState<boolean>(false)

    const [dataAccepted, setDataAccepted] = useState<boolean>(false)
    const [rulesAccepted, setRulesAccepted] = useState<boolean>(false)

    const creditService = useCreditService()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorText, setErrorText] = useState<string | null>(null)

    const submit = async () => {
        const emptyFields = getEmpty();
        setErrorFields(emptyFields)
        if (emptyFields.length > 0) {
            setErrorText("Yra neįvestų laukelių")
            return
        }
        const validation = validateFields()
        if (validation) {
            setErrorText(validation.error)
            setErrorFields([validation.field])
            return
        }
        setErrorText(null)
        setIsSubmitting(true)
        try {
            sendEvent(AnalyticsEvent.FormSubmit)
            await creditService.requestCredit({ ...form, amount: Number(form.amount), data: data })
            setSuccessOpen(true)
        } catch (e: any) {
            setErrorText(getErrorText(e.response.data.errorCode))
        }
        setIsSubmitting(false)
    }
    const validateFields = (): { field: string, error: string } | null => {
        if (!isEmail(form.email)) {
            return { field: "email", error: "Klaidingas el. paštas" }
        }
        if (form.phone.length < 8) {
            return { field: "phone", error: "Klaidingas tel. numeris" }
        }
        if (data.personCode.length !== 11) {
            return { field: "data.personCode", error: "Neteisingas asmens kodas" }
        }

        return null;
    }

    const getEmpty = (): string[] => {
        const result: string[] = [];
        Object.entries(form).forEach(([key, data]) => {
            if (key === "data") {
                return
            }
            if (typeof data !== "boolean" && !data) {
                result.push(key)
            }
        })
        Object.entries(data).forEach(([dataKey, item]) => {
            if (typeof item !== "boolean" && !item) {
                result.push(`data.${dataKey}`)
            }
        })
        return result;
    }


    const [successOpen, setSuccessOpen] = useState<boolean>(false)

    return <>
        <Stack sx={{ p: "44px" }} gap={2}>
            <FieldWithLabel label={"Paskolos suma"}><NumberField isFloat={true} onFocus={() => sendEvent(AnalyticsEvent.FormFocus)} error={errorFields.includes("amount")} value={form.amount} onChange={value => setForm({ ...form, amount: value })} /></FieldWithLabel>
            <FieldWithLabel label={"Paskolos trukmė, mėn."}>
                <Select error={errorFields.includes("periodMonths")} value={form.periodMonths} onChange={e => setForm({ ...form, periodMonths: Number(e.target.value) })}>
                    {PeriodFields.map(period => <MenuItem key={period} value={period}>{period} mėn.</MenuItem>)}
                </Select>
            </FieldWithLabel>
            {!opened && <Button variant="contained" onClick={() => {
                sendEvent(AnalyticsEvent.FormStep1)
                setOpened(true)
            }
            }>Toliau</Button>}
            {opened && <>
                <CreditFormData empty={errorFields} data={data} setData={(data: CreditRequestData) => setData(data)} form={form} setForm={(data: FormData) => setForm(data)} />
            <RulesCheckbox value={dataAccepted} label={<>Sutinku su <Link target="_blank" to="/puslapiai/partneriu-duomenu-tikrinimo-salygos">partnerių sąlygomis</Link> dėl duomenų siuntimo kredito įvertinimui</>} onChange={value => setDataAccepted(value)} />
            <RulesCheckbox value={rulesAccepted} label={<>Susipažinau ir sutinku su <Link target="_blank" to="/puslapiai/privatumo-politika">taisyklėmis bei privatumo politika</Link></>} onChange={value => setRulesAccepted(value)} />

                {errorText && <Alert severity="error">{errorText}</Alert>}
                <LoadingButton disabled={!rulesAccepted || !dataAccepted} loading={isSubmitting} variant="contained" onClick={submit}>Tęsti</LoadingButton>
            </>}
            <Divider />
            <Typography sx={{ fontSize: "10px", lineHeight: "26px" }}>
                Paskolos internetu teikiamos nuo 500 EUR iki 15'000 EUR, maksimali paskolos grąžinimo trukmė - 60 mėn., metinė palūkanų norma - nuo 6,9 proc., faktiškai taikoma bendros vartojimo kredito kainos metinė norma (BVKKMN) – nuo 9,7 proc. Pavyzdžiui, skolinantis per vieną iš mūsų atstovaujamų kredito davėjų 3'000 EUR, kai sutartis sudaroma 60 mėnesių laikotarpiui, fiksuotoji metinė palūkanų norma – 7,9 proc., mėnesins sąskaitos tvarkymo mokestis – 0,7 EUR/mėn, bendra vartojamo kredito gavėjo mokama suma – 3683,14 EUR, bendros vartojimo kredito kainos metinė norma (BVKKMN) – 9,7 proc., eilinė mėnesio įmoka – 60,69 EUR. Atsižvelgiant į atliktą Jūsų kreditingumo ir rizikos vertinimą ar pasirinkus kitokį sutarties terminą bei įmokų mokėjimo dieną, Jums gali būti pasiūlytos ir kitokios sąlygos (pavyzdžiui, metinės palūkanų normos, sutarties ir mėnesio sutarties mokesčio dydis).
            </Typography>
        </Stack>
        <SuccessDialog open={successOpen} setOpen={open => setSuccessOpen(open)}></SuccessDialog>
    </>
}

export default FormCredit