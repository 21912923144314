export type Setting = {
    field: string,
    value: string
}

export type SettingsForm = {
    params: Setting[]
}


export const MainMenu = "main-menu"
export const FooterMenu = "footer-menu"