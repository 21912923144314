import { Alert, CircularProgress } from "@mui/material"
import { Stack } from "@mui/system"
import { ErrorInvalidConfirmCode, getErrorText } from "api/errors"
import { useAuthService } from "libs/auth/hooks"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

const UserConfirm = () => {
    const [searchParams] = useSearchParams()

    const { id } = useParams()

    const service = useAuthService()

    const code = searchParams.get("code")
    const [confirming, setConfirming] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        const confirm = async (id: string, code: string) => {
            setConfirming(true)
            try {
                await service.confirmUser(id, code)
            } catch (e: any) {
                setError(getErrorText(e.response.data.errorCode))
            }
            setConfirming(false)
        }
        if (id && code) {
            confirm(id, code)
        } else {
            setError(getErrorText(ErrorInvalidConfirmCode))
        }
        id && code && confirm(id, code)
    }, [id, code, service])



    return <Stack sx={{ maxWidth: "1200px" }}>
        {confirming
            ?
            <CircularProgress />
            :
            <>{error
                ?
                <Alert severity="error">{error}</Alert>
                :
                <Alert severity="success">Vartotojas sėkmingai patvirtintas</Alert>
            }
            </>
        }
    </Stack>
}

export default UserConfirm