import { Divider, MenuItem, Select, Stack, TextField, Theme, useMediaQuery } from "@mui/material"
import NumberField from "components/NumberField"
import { CreditRequestData, CreditRequestForm, DocumentTypes, IncomeType } from "libs/credit/models"
import { FC } from "react"
import BooleanField from "./BooleanField"
import FieldWithLabel from "./FieldWithLabel"
import FormTitle from "./FormTitle"

const UnselectedValue = "null"


export type FormData = Omit<CreditRequestForm, 'amount'> & {
    amount: string,
}


const CreditFormData: FC<{ empty: string[], data: CreditRequestData, setData: (data: CreditRequestData) => void, form: FormData, setForm: (data: FormData) => void }> = ({ empty, data, setData, form, setForm }) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return <>
        <FormTitle>Asmeniniai duomenys</FormTitle>
        <Divider />
        <Stack direction={isMobile ? "column" : "row"} gap={2}>
            <FieldWithLabel label="Vardas"><TextField error={empty.includes("data.firstName")} sx={{ flex: 1 }} value={data.firstName} onChange={e => setData({ ...data, firstName: e.target.value })} /></FieldWithLabel>
            <FieldWithLabel label="Pavardė"><TextField error={empty.includes("data.lastName")} sx={{ flex: 1 }} value={data.lastName} onChange={e => setData({ ...data, lastName: e.target.value })} /></FieldWithLabel>
        </Stack>
        <FieldWithLabel label="Asmens kodas"><TextField error={empty.includes("data.personCode")} value={data.personCode} onChange={e => setData({ ...data, personCode: e.target.value })} /></FieldWithLabel>
        <FieldWithLabel label={"Ar turite sutuoktinį?"}><BooleanField value={data.married} onChange={value => setData({ ...data, married: value })} /></FieldWithLabel>
        <FieldWithLabel label="Telefono numeris"><TextField error={empty.includes("phone")} value={form.phone} onChange={e => setForm({ ...form, phone: e.target.value })} /></FieldWithLabel>
        <FieldWithLabel label="El. paštas"><TextField error={empty.includes("email")} value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} /></FieldWithLabel>
        <FieldWithLabel label="Asmens dokumento tipas">
            <Select error={empty.includes("data.documentType")} placeholder="" value={data.documentType ? data.documentType : UnselectedValue} onChange={e => setData({ ...data, documentType: e.target.value === UnselectedValue ? null : e.target.value })}>
                {DocumentTypes.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
            </Select>
        </FieldWithLabel>
        <FormTitle>Jūsų pajamos</FormTitle>
        <Divider />
        <FieldWithLabel label="Pagrindinių pajamų rūšis">
            <Select error={empty.includes("data.incomeType")} value={data.incomeType ? data.incomeType : UnselectedValue} onChange={e => setData({ ...data, incomeType: e.target.value === UnselectedValue ? null : e.target.value })}>
                {IncomeType.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
            </Select>
        </FieldWithLabel>
        <FieldWithLabel label={"Mėnėsio pajamos ”į rankas”"}><NumberField error={empty.includes("data.income")} isFloat={true} value={data.income} onChange={value => setData({ ...data, income: value })} /></FieldWithLabel>
        <FieldWithLabel label={"Kitos pajamos (aprašykite)"}><NumberField error={empty.includes("data.otherIncome")} isFloat={true} value={data.otherIncome} onChange={value => setData({ ...data, otherIncome: value })} /></FieldWithLabel>
        <FormTitle>Jūsų įsipareigojimai</FormTitle>
        <Divider />
        <FieldWithLabel label={"Mano vartojimo paskolų įmokos (Eur/mėn.)"}><TextField error={empty.includes("data.monthlyCreditOutcome")} value={data.monthlyCreditOutcome} onChange={e => setData({ ...data, monthlyCreditOutcome: e.target.value })} /></FieldWithLabel>
        <FieldWithLabel label={"Kiti mano finansiniai įsipareigojimai (Eur/mėn.)"}><TextField error={empty.includes("data.monthlyOtherOutcome")} value={data.monthlyOtherOutcome} onChange={e => setData({ ...data, monthlyOtherOutcome: e.target.value })} /></FieldWithLabel>
    </>
}
export default CreditFormData