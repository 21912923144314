import { Box, Divider, Drawer, IconButton, ListItem } from "@mui/material"
import { FC, useContext, useState } from "react"

import CloseIcon from "@mui/icons-material/Close";
import { MenuItemData } from "./Menu";
import { Link } from "react-router-dom";
import { SettingCompanyPhone, SettingsContext } from "libs/settings/providers";
import UserMenu from "components/UserMenu";
import MenuIcon from '@mui/icons-material/Menu';


const MobileMenu: FC<{ menuItems: MenuItemData[] }> = ({ menuItems }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { getSetting } = useContext(SettingsContext)
  const phoneNumber = getSetting(SettingCompanyPhone)

  return <>
    <IconButton onClick={() => setOpen(true)}>
      <MenuIcon />
    </IconButton>
    <Drawer
      keepMounted={true}
      PaperProps={{
        sx: { width: "100%", maxWidth: "350px" },
      }}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box
        sx={{
          p: 2,
          height: 1,
        }}
      >
        <IconButton onClick={() => setOpen(false)} sx={{ mb: 2 }}>
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb: 1 }} />

        <Box sx={{ mb: 1 }}>
          {menuItems.map(item => <ListItem key={item.path}><Link onClick={() => setOpen(false)} style={{ color: "black" }} to={item.path}>{item.name}</Link></ListItem>)}
          {phoneNumber && <ListItem><a style={{ color: "#9D6209" }} href={`tel:${phoneNumber}`}>{phoneNumber}</a></ListItem>}
        </Box>
        <Divider sx={{ mb: 1 }} />

        <UserMenu onOpen={() => setOpen(false)} />
      </Box>
    </Drawer>
  </>
}

export default MobileMenu