import { Alert, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getErrorText } from "api/errors"
import { FullCreditRequest, RequestStatusEnum } from "libs/credit/models"
import LoadingButton from "@mui/lab/LoadingButton"
import { useCreditService } from "libs/credit/hooks"
import CreditRequestInfoBlock from "components/CreditRequestInfoBlock"
import { useUploadService } from "libs/upload/hooks"




const AdminRequestEdit: FC = () => {
    const { id } = useParams()
    const service = useCreditService()
    const uploadService = useUploadService()

    const [data, setData] = useState<FullCreditRequest | null>(null)

    const [status, setStatus] = useState<RequestStatusEnum>(RequestStatusEnum.New)
    const [isStatusChanging, setStatusChanging] = useState<boolean>(false)
    const [statusChangeError, setStatusChangeError] = useState<string>("")

    const [isUploading, setIsUploading] = useState<boolean>(false)


    useEffect(() => {
        const fetch = async (id: string) => {
            try {
                const data = await service.getCreditRequest(id, null)
                setData(data)
                data && setStatus(data.status)
            } catch (e) {
                //TODO error
            }
        }
        id && fetch(id)
    }, [id, service])

    const changeStatus = async (id: string, status: RequestStatusEnum) => {
        setStatusChanging(true)
        setStatusChangeError("")
        try {
            await service.changeCreditRequestStatus(id, status)
            setData(current => current ? ({ ...current, status: status }) : null)
        } catch (e: any) {
            const error = e.response.data.errorCode
            setStatusChangeError(getErrorText(error))
        }
        setStatusChanging(false)
    }

    if (!data) {
        return <Stack sx={{ width: "100%" }} alignItems="center">
            <CircularProgress />
        </Stack>
    }

    const statusToText = (status: RequestStatusEnum): string => {
        switch (status) {
            case RequestStatusEnum.Closed:
                return "Uždaryta"
            case RequestStatusEnum.New:
                return "Naujas"
            case RequestStatusEnum.InProgress:
                return "Pateikta kreditoriams"
        }
    }
    const statusColor = (status: RequestStatusEnum): string => {
        switch (status) {
            case RequestStatusEnum.Closed:
                return "#af0000"
            case RequestStatusEnum.New:
                return "#34a18e"
            case RequestStatusEnum.InProgress:
                return "#1673ff"
        }
    }

    const uploadContract = async (id: string, file: File) => {
        setIsUploading(true)

        try {
            const uploadedFile = await uploadService.uploadFile(file)
            await service.assignContractFile(id, uploadedFile.id)
            setData(current => current ? ({ ...current, contractFile: file.name }) : null)
        } catch (e: any) {
            console.error(e.reponse.data.errorCode)
        }
        setIsUploading(false)
    }

    return <Stack alignItems="center">
        <Stack direction="row" alignItems={"center"} sx={{ width: "1232px" }}>
            <h2 style={{ flex: 1 }}>{`Paraiška #${data.number}`}</h2>
            <Stack direction="row" gap={1}>
                <Typography>Būklė:</Typography>
                <Typography color={statusColor(data.status)}>{statusToText(data.status)}</Typography>
            </Stack>
        </Stack>
        <Stack direction="row" gap={4}>
            <CreditRequestInfoBlock data={data} />
            <Stack>
                <h4>Administravimas</h4>
                <Paper sx={{ p: "20px", width: "600px" }}>
                    <Stack gap={2}>
                        <FormControl fullWidth>
                            <InputLabel>Paraiškos būklė</InputLabel>
                            <Select
                                value={status}
                                label="Paraiškos būklė"
                                onChange={e => setStatus(e.target.value as RequestStatusEnum)}
                            >
                                {Object.values(RequestStatusEnum).map(item => <MenuItem key={item} value={item}>{statusToText(item)}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {statusChangeError && <Alert severity="error">{statusChangeError}</Alert>}
                        <LoadingButton variant="contained" loading={isStatusChanging} onClick={() => changeStatus(data.id, status)}>Saugoti</LoadingButton>
                    </Stack>
                </Paper>
                <Paper sx={{ mt: "20px", p: "20px", width: "600px" }}>
                    <Stack gap={2}>
                        <Typography fontWeight={"bold"}>Finansinis tarpininkavimas</Typography>
                        <Stack direction="row" gap={1}><Typography>Apmokėta:</Typography><Typography fontWeight="bold">{data.paid ? "Taip" : "Ne"}</Typography></Stack>
                        <Typography>Sąskaita už finansinį tarpininkavimą:</Typography>
                        {data.contractFile && <a rel="noreferrer" target="_blank" href={service.getContractDownloadUrl(data.id, null)}>{data.contractFile}</a>}

                        <LoadingButton
                            loading={isUploading}
                            variant="contained"
                            component="label"
                        >
                            {data.contractFile ? "Pakeisti sąskaitą" : "Įkelti sąskaitą"}
                            <input
                                value=""
                                onChange={e => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        const file = e.target.files[0]
                                        uploadContract(data.id, file)
                                    }
                                }}
                                type="file"
                                hidden
                            />
                        </LoadingButton>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    </Stack>
}


export default AdminRequestEdit