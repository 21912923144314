import { Column, Datatable } from "@brunas/react-mui-datatable"
import { Button, IconButton, Stack, Typography } from "@mui/material"
import { CreditorDatatableItem } from "libs/credit/models"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import { useDatatableWithApi } from "libs/datatable/hooks"

const CreditorList = () => {
    const data = useDatatableWithApi<CreditorDatatableItem, null>({ endpoint: "/admin/creditors/datatable", config: null })

    const navigate = useNavigate()

    const columns = useMemo<Column<CreditorDatatableItem>[]>(() => [
        {
            value: (item: CreditorDatatableItem) => item.title,
            header: "Pavadinimas"
        },
        {
            value: (item: CreditorDatatableItem) => item.users,
            header: "Vartotojų"
        },
        {
            header: "Veiksmai",
            getActions: (params) => {
                return [
                    <Button onClick={() => navigate(`/admin/creditors/${params.row.id}`)}>Redaguoti</Button>
                ]
            }
        }
    ], [navigate])


    return <Stack gap={2} sx={{ width: "100%" }}>
        <Stack direction="row" gap={1}>
            <Typography sx={{ fontSize: "35px" }}>Kreditoriai</Typography>
            <IconButton onClick={() => navigate("/admin/creditors/create")} color="primary"><AddIcon fontSize="large" /></IconButton>
        </Stack>
        <Datatable {...data} globalSearchEnabled={true} columns={columns} />
    </Stack>
}

export default CreditorList