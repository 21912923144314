import axios from "axios";
import dayjs from "dayjs";

const ExtendedAxios = axios.create();

export const AdaptIsoDates = (response:any) => {
    const adaptRecursive = (obj: any) => {
        Object.keys(obj).forEach((key) => {
            if (obj[key] !== null && typeof obj[key] === 'object') {
                adaptRecursive(obj[key]);
            } else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(obj[key])) {
                obj[key] = dayjs(obj[key]);
            }
        });
    };

    adaptRecursive(response.data);
    return response;
}

ExtendedAxios.interceptors.response.use(AdaptIsoDates);

export default ExtendedAxios