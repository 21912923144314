import { Alert, CircularProgress, Paper, Stack, Typography } from "@mui/material"
import { getErrorText } from "api/errors"
import { useCreditService } from "libs/credit/hooks"
import { CreditorResponse, CreditorResponseStatus, FullCreditRequest, RequestStatusEnum } from "libs/credit/models"
import { useCallback, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import RequestResponses from "./RequestResponses"


const ViewRequest = () => {
    const [data, setData] = useState<FullCreditRequest | null>(null)
    const { id } = useParams()
    const service = useCreditService()

    const [fetchError, setFetchError] = useState<string | null>(null)

    const [searchParams] = useSearchParams()

    const viewCode = searchParams.get('code')

    const [signSuccess, setSignSuccess] = useState<boolean>(false)


    const fetch = useCallback(async () => {
        if (id) {
            try {
                const data = await service.getCreditRequest(id, viewCode)
                setData(data)
            } catch (e: any) {
                setFetchError(getErrorText(e.response.data.errorCode))
            }
        }
    }, [service, id, viewCode])

    useEffect(() => {
        fetch()
    }, [fetch])

    if (fetchError) {
        return <Alert severity="error">{fetchError}</Alert>
    }

    if (!data) {
        return <Stack alignItems="center">
            <CircularProgress />
        </Stack>
    }

    const signedColor = (response: CreditorResponse): string => {
        return response.status === CreditorResponseStatus.PreSigned ? "#ED6C02" : "#2E7D32"
    }

    const signedText = (response: CreditorResponse): string => {
        return response.status === CreditorResponseStatus.PreSigned ? `Sudaroma su ${response.creditor}` : `Sudaryta su ${response.creditor}`
    }

    return <Stack sx={{ maxWidth: "1200px" }} gap={2}>
        <h2>Paraiška #{data.number}</h2>
        {signSuccess && <Alert severity="success">Netrukus su jumis susisieks kreditoriaus vadybininkas dėl sutarties sudarymo ir kredito išmokėjimo</Alert>}
        {data.status === RequestStatusEnum.New && <Alert severity="info">Jūsų paraiška dar nėra patvirtinta - patikrinkite vėliau</Alert>}
        <Paper sx={{ p: "20px" }}>
            <Stack gap={1}>
                <h2>Vartojimo kreditas</h2>
                <Stack direction="row" sx={{ width: "800px", maxWidth:"100%" }}>
                    <Typography sx={{ flex: 1 }}>Paskolos suma:</Typography>
                    <Typography fontWeight="bold" sx={{ flex: 1 }}>{data.amount}</Typography>
                </Stack>
                <Stack direction="row" sx={{ width: "800px", maxWidth:"100%" }}>
                    <Typography sx={{ flex: 1 }}>Trukmė:</Typography>
                    <Typography fontWeight="bold" sx={{ flex: 1 }}>{data.periodMonths} mėn.</Typography>
                </Stack>
                {data.signedResponse && <Stack direction="row" sx={{ width: "800px", maxWidth:"100%" }}>
                    <Typography sx={{ flex: 1 }}>Sutartis:</Typography>
                    <Typography fontWeight="bold" sx={{ flex: 1, color: signedColor(data.signedResponse) }}>{signedText(data.signedResponse)}</Typography>
                </Stack>}
            </Stack>
        </Paper>
        {!data.signedResponse && data.status === RequestStatusEnum.InProgress && <RequestResponses setSignSuccess={() => setSignSuccess(true)} refreshData={fetch} id={data.id} />}
    </Stack>
}

export default ViewRequest