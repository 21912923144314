import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Button, Stack, TextField, Typography } from "@mui/material"
import { getErrorText } from "api/errors"
import { useAuthService } from "libs/auth/hooks"
import { UserDataContext } from "libs/auth/providers"
import isEmail from "libs/utils/isEmail"
import { FC, useContext, useState } from "react"

const LoginContent: FC<{ onClose: () => void, openRegister: () => void, openReset: () => void }> = ({ onClose, openRegister, openReset }) => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const [loginError, setLoginError] = useState<string>("")

    const { refreshData } = useContext(UserDataContext)

    const [isLogging, setIsLogging] = useState<boolean>(false)

    const authService = useAuthService();


    const validate = (): string | null => {
        if (!isEmail(email)) {
            return getErrorText("invalid-credentials")
        }

        if (!password) {
            return getErrorText("invalid-credentials")
        }

        return null
    }

    const login = async () => {
        setLoginError("")
        const formError = validate()
        if (formError) {
            setLoginError(formError)
            return
        }
        setIsLogging(true)
        try {
            await authService.login(email, password)
            await refreshData()
            onClose()
        } catch (e: any) {
            const error = e.response.data.errorCode
            setLoginError(getErrorText(error))
        }
        setIsLogging(false)
    }

    return <Stack gap={2} sx={{ pt: "5px" }}>
        <TextField label={"El. paštas"} value={email} onChange={e => setEmail(e.target.value)} />
        <TextField label={"Slaptažodis"} value={password} onChange={e => setPassword(e.target.value)} type="password" />
        {loginError && <Alert severity="error">{loginError}</Alert>}
        <Stack gap={1}>
            <LoadingButton loading={isLogging} variant={"contained"} onClick={login}>Prisijungti</LoadingButton>
            <Button onClick={openReset}>Pamiršote slaptažodį?</Button>
        </Stack>

        <Stack direction="row" alignItems={"center"} gap={1}>
            <Typography>Neturite paskyros?</Typography>
            <Button onClick={openRegister}>Registruotis</Button>
        </Stack>
    </Stack>
}

export default LoginContent