import { UserDataContext } from "libs/auth/providers"
import { useMemo, useContext } from "react"
import { SettingsApiService } from "./api"

export const useSettingsService = (): SettingsApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new SettingsApiService(authorizedAxios)
    }, [authorizedAxios])
}