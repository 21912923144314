export const ErrorNotFound = "not_found"
export const ErrorInvalidConfirmCode = "invalid-confirm-code"



const Errors: { [propKey: string]: string } = {
    "file-already-used": "Failas jau panaudotas",
    "missing-file": "Nenusiųstas failas",
    "unauthorized": "Būtina prisijungti",
    "invalid-credentials": "Klaidingi prijungimo duomenys",
    "internal": "Sistemos klaida",
    "forbidden": "Neturite teisių pasiektį turinį",
    "invalid-form": "Klaida formoje",
    "invalid-user-type": "Vartotojas netinkamo tipo",
    "user-already-has-creditor": "Vartotojas jau priskirtas kreditoriui",
    "creditor-is-not-assigned": "Vartotojas nepriskirtas kreditoriui",
    "email-already-in-use": "El. pašto adresas jau užregistruotas",
    "invalid-request-status": "Neteisingas užklausos statusas",
    "invalid-response-status": "Neteisingas pasiūlymo statusas",
    "request-already-signed": "Sutartis jau pasirašyta",
    [ErrorInvalidConfirmCode]: "Neteisingas patvirtinimo kodas",
    "user-already-confirmed": "Vartotojas jau patvirtintas",
    "user-not-confirmed": "Vartotojas nėra patvirtinas. Patikrinkite el. paštą",
    [ErrorNotFound]: "Nerasta",
}

export const getErrorText = (errorKey: string): string => {
    const errorText = Errors[errorKey]
    if (!errorText) {
        return "Nežinoma klaida"
    }
    return errorText
}