import { Checkbox, FormGroup, Stack, FormControlLabel } from "@mui/material"
import React, { FC } from "react"



const RulesCheckbox: FC<{ onChange: (value: boolean) => void, value: boolean, label: React.ReactNode }> = ({ onChange, value, label }) => {
    return <Stack direction="row" sx={{ alignItems: "center" }}>
        <FormGroup>
            <FormControlLabel control={<Checkbox checked={value} onChange={e => onChange(e.target.checked)} />} label={label} />
        </FormGroup>
    </Stack>
}


export default RulesCheckbox