import { Chip, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { CreditorCreditRequestDatatableItem, CreditorResponseStatus, RequestTypeList } from "libs/credit/models"
import { Column, Datatable, FilterValueUpdateProps, SelectFilter, SelectFilterValueType, Sorting, SortingDirectionEnum } from "@brunas/react-mui-datatable"
import { useDatatableWithApi } from "libs/datatable/hooks"


const defaultSorting: Sorting[] = [
    {
        field: "date",
        direction: SortingDirectionEnum.Descending
    }
]

const getStatusName = (status: CreditorResponseStatus): string => {
    switch (status) {
        case CreditorResponseStatus.Accepted:
            return "Pateiktas pasiūlymas"
        case CreditorResponseStatus.Declined:
            return "Atmesta"
        case CreditorResponseStatus.PreSigned:
            return "Sudaroma sutartis"
        case CreditorResponseStatus.Signed:
            return "Sudaryta sutartis"
    }
}

const StatusBadge: FC<{ item: CreditorCreditRequestDatatableItem }> = ({ item }) => {
    if (!item.status) {
        return <Chip color="info" label="Laukia pasiūlymo" />
    }

    switch (item.status) {
        case CreditorResponseStatus.Accepted:
            return <Chip color="primary" label={getStatusName(item.status)} />
        case CreditorResponseStatus.Declined:
            return <Chip color="error" label={getStatusName(item.status)} />
        case CreditorResponseStatus.PreSigned:
            return <Chip color="warning" label={getStatusName(item.status)} />
        case CreditorResponseStatus.Signed:
            return <Chip color="success" label={getStatusName(item.status)} />
    }
}


const CreditorRequestsList: FC = () => {
    const data = useDatatableWithApi<CreditorCreditRequestDatatableItem, null>({ endpoint: "/creditor-admin/credit-requests/datatable", config: null, defaultSorting: defaultSorting })


    const navigate = useNavigate()

    const columns = useMemo<Column<CreditorCreditRequestDatatableItem>[]>(() => [
        {
            value: (item: CreditorCreditRequestDatatableItem) => `#${item.number}`,
            header: "ID"
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => item.date.format("YYYY-MM-DD HH:mm"),
            header: "Data",
            sortingField: "date",
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => item.requestAuthor,
            header: "Pareiškėjas"
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => `${item.amount} €`,
            header: "Paskolos suma",
            sortingField: "amount",
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => `${item.periodMonths} mėn.`,
            header: "Terminas",
            sortingField: "periodMonths",
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => RequestTypeList.find(x => x.type === item.type)?.name,
            header: "Paskolos tipas"
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => <StatusBadge item={item} />,
            header: "Būklė"
        },
        {
            value: (item: CreditorCreditRequestDatatableItem) => item.responseAuthor ? item.responseAuthor : "---",
            header: "Administratorius"
        }
    ], [])

    const filters = useCallback((props: FilterValueUpdateProps) => [
        <SelectFilter key="status" {...props} field="status" label="Būklė" filterValueType={SelectFilterValueType.String} values={Object.values(CreditorResponseStatus).map(item => ({
            value: item,
            label: getStatusName(item)
        }))} />
    ], [])


    return <Stack gap={2} sx={{ width: "100%" }}>
        <Typography sx={{ fontSize: "35px" }}>Paraiškos</Typography>
        <Datatable onRowClick={row => {
            navigate(`/creditor-admin/credit-requests/${row.id}`)
        }} {...data} globalSearchEnabled={false} columns={columns} filters={filters} />
    </Stack>
}


export default CreditorRequestsList