import { Stack, Button, Typography, Paper, Theme, useMediaQuery } from "@mui/material"
import { RequestTypeList } from "libs/credit/models"
import { Link, Outlet, useLocation } from "react-router-dom"

const selectedType = (path: string) => {
    const type = RequestTypeList.find(item => path === item.fullPath)
    return type ? type : RequestTypeList[0]
}

const Home = () => {

    const location = useLocation();

    const selected = selectedType(location.pathname)

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return <>
        <Stack sx={{ width: "700px", maxWidth: "100%" }} direction="column" gap={3}>
            <Stack direction={isMobile ? "column" : "row"} gap={2}>
                {RequestTypeList.map(item => <Button key={item.type} component={Link} to={item.fullPath} color={selected === item ? "secondary" : "info"} variant={"outlined"}>{item.name}</Button>)}
            </Stack>

            <Typography sx={{ fontSize: isMobile ? "25px" : "35px", fontWeight: 500 }}>
                {selected.infoTitle}
            </Typography>
            <Typography sx={{ fontSize: isMobile ? "18px" : "25px", fontWeight: 400 }}>
                {selected.infoDescription}
            </Typography>
            <Paper>
                <Outlet />
            </Paper>
        </Stack>
    </>
}


export default Home