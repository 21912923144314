import { Alert, Stack, Typography } from "@mui/material"
import { UserDataContext } from "libs/auth/providers"
import { useContext } from "react"
import MyReqeustsDatatable from "./Datatable"


const MyRequests = () => {
    const { userData } = useContext(UserDataContext)

    return <Stack sx={{ maxWidth: "1200px" }} gap={2}>
        <Typography sx={{ fontSize: "35px" }}>Mano paraiškos</Typography>
        {!userData
            ?
            <Alert severity="info">Būtina prisijungti</Alert>
            :
            <MyReqeustsDatatable />
        }
    </Stack>
}

export default MyRequests