import { AxiosService } from "libs/utils/AxiosService";
import { Claims, RegisterForm } from "libs/auth/models";
import ExtendedAxios from "../utils/ExtendedAxios";
import { Response } from "../../api/models";


export type LoginRequest = {
    email: string,
    password: string,
}
export type PasswordResetForm = {
    password: string,
}

export class AuthApiService extends AxiosService {
    host: string = "/api/auth";

    login(email: string, password: string): Promise<void> {
        const request: LoginRequest = {
            email: email,
            password: password,
        }

        return this.axios.post(`${this.host}/login`, request)

    }

    register(form: RegisterForm): Promise<void> {
        return this.axios.post(`${this.host}/register`, form)
    }

    me(): Promise<Claims> {
        return this.axios.get<Response<Claims>>(`${this.host}/me`).then(value => value.data.data)
    }

    logout(): Promise<boolean> {
        return this.axios.post<Response<any>>(`${this.host}/logout`).then(() => true).catch(() => false)
    }

    confirmUser(id: string, code: string): Promise<void> {
        return this.axios.post(`${this.host}/confirm/${id}?code=${code}`)
    }

    startRecover(email: string): Promise<void> {
        return this.axios.post(`${this.host}/recovery?email=${email}`)
    }

    resetPassword(userId: string, code: string, form: PasswordResetForm): Promise<void> {
        return this.axios.post(`${this.host}/reset-password/${userId}?code=${code}`, form)
    }
}

export const refreshTokenFn = (): Promise<boolean> => {
    const host = "/api/auth"
    return ExtendedAxios.post(`${host}/refresh`).then(() => true).catch(() => false)
}