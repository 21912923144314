import { Response } from "api/models";
import { AxiosService } from "libs/utils/AxiosService";
import { Post, PostForm } from "./models";

export class PostsApiService extends AxiosService {
    adminEndpoint: string = "/api/admin/posts";
    publicEndpoint: string = "/api/posts";

    create(form: PostForm): Promise<Post> {
        return this.axios.post<Response<Post>>(`${this.adminEndpoint}`, form).then(x => x.data.data)
    }

    edit(id: string, form: PostForm): Promise<Post> {
        return this.axios.put<Response<Post>>(`${this.adminEndpoint}/${id}`, form).then(x => x.data.data)
    }

    get(id: string): Promise<Post> {
        return this.axios.get<Response<Post>>(`${this.publicEndpoint}/${id}`).then(x => x.data.data)
    }

    getList(page: number): Promise<Post[]> {
        return this.axios.get<Response<Post[]>>(`${this.publicEndpoint}?page=${page}`).then(x => x.data.data)
    }

    getPagesCount(): Promise<number> {
        return this.axios.get<Response<number>>(`${this.publicEndpoint}/pages-count`).then(x => x.data.data)
    }
}