import { Box, useTheme } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"
import './MenuItem.scss'

const MenuItem: FC<{ title: string, selected?: boolean, path: string }> = ({ title, selected, path }) => {
    const theme = useTheme()
    return <Link style={{textDecoration:"none"}} to={path}>
        <Box className="menu-item" sx={{
            color: selected ? theme.palette.primary.main : undefined,
            borderBottom: selected ? `4px solid ${theme.palette.primary.main}` : undefined
        }}>
            {title}
        </Box>
    </Link>
}


export default MenuItem