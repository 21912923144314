import { Response } from "api/models";
import { AxiosService } from "libs/utils/AxiosService";
import { Page, PageForm } from "./models";

export class PagesApiService extends AxiosService {
    adminEndpoint: string = "/api/admin/pages";
    publicEndpoint: string = "/api/pages";

    create(form: PageForm): Promise<Page> {
        return this.axios.post<Response<Page>>(`${this.adminEndpoint}`, form).then(x => x.data.data)
    }

    edit(id: string, form: PageForm): Promise<Page> {
        return this.axios.put<Response<Page>>(`${this.adminEndpoint}/${id}`, form).then(x => x.data.data)
    }

    get(id: string): Promise<Page> {
        return this.axios.get<Response<Page>>(`${this.adminEndpoint}/${id}`).then(x => x.data.data)
    }

    getAll(): Promise<Page[]> {
        return this.axios.get<Response<Page[]>>(`${this.adminEndpoint}`).then(x => x.data.data)
    }

    getByUrl(url: string): Promise<Page> {
        return this.axios.get<Response<Page>>(`${this.publicEndpoint}/${url}`).then(x => x.data.data)
    }
}