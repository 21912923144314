import { Divider, Theme, Typography, useMediaQuery } from "@mui/material"
import { Stack } from "@mui/system"
import { FC } from "react"

const FooterBlock: FC<{ title: string, children: React.ReactNode | React.ReactNode[] }> = ({ title, children }) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    return <Stack direction="column" gap={2} sx={{ maxWidth: "463px", width: isMobile ? "100%" : "initial" }}>
        <Typography sx={{ fontSize: "35px" }}>{title}</Typography>
        <Divider sx={{ width: "150px" }} />
        <Stack>
            {children}
        </Stack>
    </Stack>
}

export default FooterBlock