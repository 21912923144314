import React, { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Claims } from "./models";
import axios, { AxiosInstance } from "axios";
import { AuthApiService, refreshTokenFn } from "libs/auth/api";
import ExtendedAxios, { AdaptIsoDates } from "libs/utils/ExtendedAxios";

export type UserContext = {
    userData: Claims | null,
    refreshData: () => Promise<void>,
    onSessionFail: () => void,
    loading: boolean,
    initialLoading: boolean,
    authorizedAxios: AxiosInstance,
}

export const UserDataContext = createContext<UserContext>({ userData: null, loading: false, initialLoading: false, refreshData: async () => { }, onSessionFail: () => { }, authorizedAxios: axios.create() })

export const UserDataProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const authorizedAxios = useMemo(() => {
        const AuthorizedAxiosInstance = axios.create();
        let isRefreshing = false;
        let subscribers: ((ok: boolean) => void)[] = [];

        const onRefreshed = (ok: boolean) => {
            if (!ok) {
                setUserData(null)
            }
            subscribers.map(cb => cb(ok));
        }
        function subscribeTokenRefresh(cb: (ok: boolean) => void) {
            subscribers.push(cb);
        }

        //transform iso dates
        AuthorizedAxiosInstance.interceptors.response.use(AdaptIsoDates);

        // Response interceptor for API calls
        AuthorizedAxiosInstance.interceptors.response.use((response: any) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                const result = new Promise((resolve, reject) => {
                    subscribeTokenRefresh(async (ok: boolean) => {
                        if (!ok) {
                            reject(error)
                        } else {
                            ExtendedAxios.request({ url: originalRequest.url, method: originalRequest.method, data: originalRequest.data }).then(resolve).catch(reject)
                        }
                    });
                });

                if (!isRefreshing) {
                    isRefreshing = true
                    refreshTokenFn().then((ok => {
                        isRefreshing = false
                        onRefreshed(ok)
                        subscribers = []
                    }))

                }

                //add to queue to execute after refresh finished
                return await result;
            }
            return Promise.reject(error);
        });

        return AuthorizedAxiosInstance
    }, [])

    const authService = useMemo(() => new AuthApiService(authorizedAxios), [authorizedAxios])

    const [userData, setUserData] = useState<Claims | null>(null)
    const [userDataLoading, setUserDataLoading] = useState<boolean>(true)
    const [initialLoading, setInitialLoading] = useState<boolean>(true)


    const fetchUserData = useCallback(async () => {

        return new Promise<void>(async resolve => {
            setUserDataLoading(true)
            try {
                const data = await authService.me()
                setUserData(data)
            } catch (e) {
            }
            setUserDataLoading(false)
            setInitialLoading(false)
            resolve()
        })
    }, [authService])



    useEffect(() => {
        fetchUserData()
    }, [fetchUserData])


    return <UserDataContext.Provider value={{ initialLoading: initialLoading, authorizedAxios: authorizedAxios, onSessionFail: () => setUserData(null), loading: userDataLoading, userData: userData, refreshData: fetchUserData }}>
        {children}
    </UserDataContext.Provider>
}