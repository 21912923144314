import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, CircularProgress, Paper, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, useContext, useEffect, useState } from "react"
import 'react-quill/dist/quill.snow.css';
import { getErrorText } from "api/errors"
import { SettingCompanyAddress, SettingCompanyEmail, SettingCompanyName, SettingCompanyPhone, SettingsContext } from "libs/settings/providers"
import { FooterMenu, MainMenu, SettingsForm } from "libs/settings/models";
import Menu from "./Menu";
import { usePages } from "libs/pages/hooks";
import { useSettingsService } from "libs/settings/hooks";

const Settings: FC = () => {
    const { settings, loaded, updateSettings } = useContext(SettingsContext)

    const service = useSettingsService()

    const { pages, isLoading } = usePages()


    const [form, setForm] = useState<SettingsForm>({ params: settings })
    const [formError, setFormError] = useState<string>("")
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        if (loaded) {
            setForm({ params: settings })
        }
    }, [settings, loaded])

    const getFormValue = (key: string) => {
        const item = form.params.find(item => item.field === key)
        return item ? item.value : ""
    }

    const updateFormValue = (key: string, value: string) => {
        setForm(current => ({ params: [...current.params.filter(item => item.field !== key), { field: key, value: value }] }))
    }

    const submit = async () => {
        setFormError("")
        setIsSubmitting(true)
        try {
            await service.update(form)
            updateSettings(form.params)
        } catch (e: any) {
            const error = e.response.data.errorCode
            setFormError(getErrorText(error))
        }
        setIsSubmitting(false)
    }


    if (!loaded) {
        return <Stack sx={{ width: "100%" }} alignItems="center">
            <CircularProgress />
        </Stack>
    }


    return <Stack alignItems="center">
        <Stack sx={{ width: "600px" }} gap={2}>
            <Paper sx={{ p: "20px" }}>
                <h2>Kontaktai</h2>
                <Stack gap={2} sx={{ width: "100%" }}>
                    <TextField size="small" label="Įmonės pavadinimas" value={getFormValue(SettingCompanyName)} onChange={e => updateFormValue(SettingCompanyName, e.target.value)}></TextField>
                    <TextField size="small" label="Įmonės adresas" value={getFormValue(SettingCompanyAddress)} onChange={e => updateFormValue(SettingCompanyAddress, e.target.value)}></TextField>
                    <TextField size="small" label="Kontaktinis telefonas" value={getFormValue(SettingCompanyPhone)} onChange={e => updateFormValue(SettingCompanyPhone, e.target.value)}></TextField>
                    <TextField size="small" label="Kontaktinis el. paštas" value={getFormValue(SettingCompanyEmail)} onChange={e => updateFormValue(SettingCompanyEmail, e.target.value)}></TextField>

                    {formError && <Alert severity="error">{formError}</Alert>}
                    <LoadingButton variant="contained" loading={isSubmitting} onClick={submit}>Saugoti</LoadingButton>
                </Stack>
            </Paper>

            {!isLoading && <>
                <Menu title="Pagrindinis meniu" pages={pages} menuKey={MainMenu} />
                <Menu title="Meniu apačioje" pages={pages} menuKey={FooterMenu} />
            </>}
        </Stack>
    </Stack>
}


export default Settings