import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, Paper, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import { getErrorText } from "api/errors"
import { useAuthService } from "libs/auth/hooks"
import { useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"


type Form = {
    password: string,
    password2: string,
}

const minPasswordLength = 8

const PasswordReset = () => {
    const [searchParams] = useSearchParams()

    const { id } = useParams()

    const service = useAuthService()

    const [form, setForm] = useState<Form>({
        password: "",
        password2: ""
    })
    const code = searchParams.get("code")
    const [resetting, setResetting] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)


    const validate = (): string | null => {
        if (form.password.length < minPasswordLength) {
            return `Slaptažodį turi sudaryti bent ${minPasswordLength} simboliai`
        }
        if (form.password !== form.password2) {
            return "Nesutampa slaptažodžiai"
        }

        return null
    }

    const confirm = async (id: string, code: string) => {
        setError("")
        const frontError = validate()
        if (frontError) {
            setError(frontError)
            return
        }
        setResetting(true)
        try {
            await service.resetPassword(id, code, { password: form.password })
            setSuccess(true)
        } catch (e: any) {
            setError(getErrorText(e.response.data.errorCode))
        }
        setResetting(false)
    }

    if (!id || !code) {
        return <></>
    }

    return <Stack alignItems={"center"}>
        <Paper sx={{ p: "20px", width: "400px" }}>
            <Stack gap={2} sx={{ pt: "5px" }}>
                {success ? <>
                    <Alert severity="success">Slaptažodis pakeistas</Alert>
                </> : <>

                    <TextField label={"Naujas slaptažodis"} value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} type="password" />
                    <TextField label={"Pakartokite"} value={form.password2} onChange={e => setForm({ ...form, password2: e.target.value })} type="password" />
                    {error && <Alert severity="error">{error}</Alert>}
                    <LoadingButton loading={resetting} variant={"contained"} onClick={() => confirm(id, code)}>Pakeisti</LoadingButton>
                </>}

            </Stack>
        </Paper>
    </Stack>
}

export default PasswordReset