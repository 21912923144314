import { Button, Stack } from "@mui/material"
import { FC } from "react"


const BooleanField: FC<{ onChange: (value: boolean) => void, value: boolean }> = ({ onChange, value }) => {
    return <Stack direction="row" gap={1}>
        <Button sx={{ paddingX: "25px" }} variant={value ? "contained" : "outlined"} onClick={() => onChange(true)}>Taip</Button>
        <Button sx={{ paddingX: "25px" }} variant={!value ? "contained" : "outlined"} onClick={() => onChange(false)}>Ne</Button>
    </Stack>
}

export default BooleanField