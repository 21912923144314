import { AuthApiService } from "libs/auth/api"
import { UserDataContext } from "libs/auth/providers"
import { useMemo, useContext } from "react"

export const useAuthService = (): AuthApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new AuthApiService(authorizedAxios)
    }, [authorizedAxios])
}