import { UserDataContext } from "libs/auth/providers"
import { useContext, useMemo } from "react"
import { CreditApiService, CreditorsApiService } from "./api"

export const useCreditorsService = (): CreditorsApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new CreditorsApiService(authorizedAxios)
    }, [authorizedAxios])
}

export const useCreditService = (): CreditApiService => {
    const { authorizedAxios } = useContext(UserDataContext)

    return useMemo(() => {
        return new CreditApiService(authorizedAxios)
    }, [authorizedAxios])
}

