import { AppBar, Box, CircularProgress, CssBaseline, Divider, Stack, Theme, Toolbar, Typography } from "@mui/material"
import { Link, Outlet } from "react-router-dom"
import Menu from "components/Menu";
import logo from 'assets/img/logo.png';
import Footer from "components/Footer";
import UserMenu from "components/UserMenu";
import { useContext, useState } from "react";
import { UserDataContext } from "libs/auth/providers";
import { MenuItemData } from "components/Menu/Menu";
import { createContext } from "react";
import { SettingCompanyPhone, SettingsContext } from "libs/settings/providers";
import CookieConsent from "react-cookie-consent";
import { useMenu } from "libs/menu/hooks";
import { MainMenu } from "libs/settings/models";
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileMenu from "components/Menu/MobileMenu";

const menuItems: MenuItemData[] = [
    {
        path: "/naujienos",
        name: "Naujienos",
    }
]

type AuthDialogContextType = {
    open: boolean,
    setOpen: (value: boolean) => void,
}

export const AuthDialogContext = createContext<AuthDialogContextType>({ open: false, setOpen: () => { } })

const Layout = () => {
    const { menuItems: mainMenuItems, isLoading: mainMenuLoading } = useMenu(MainMenu)


    const { initialLoading } = useContext(UserDataContext)
    const { getSetting } = useContext(SettingsContext)

    const [authDialogOpen, setAuthDialogOpen] = useState<boolean>(false)

    const phoneNumber = getSetting(SettingCompanyPhone)

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));


    if (initialLoading || mainMenuLoading) {
        return <Stack alignItems="center"><CircularProgress /></Stack>
    }

    const allMenuItems = [...menuItems, ...mainMenuItems.map(item => ({
        path: `/puslapiai/${item.url}`,
        name: item.title,
    }))]

    return <AuthDialogContext.Provider value={{ open: authDialogOpen, setOpen: (value: boolean) => setAuthDialogOpen(value) }}>
        <CookieConsent buttonStyle={{ background: "rgb(52,161,142)", border: "0px", borderRadius: "0px", boxShadow: "none", color: "white", cursor: "pointer", padding: "15px 30px", margin: "15px" }} buttonText="Sutinku">Informuojame, kad šioje svetainėje naudojami slapukai (angl. ,,cookies"). <br />Norėdami tęsti naršymą, paspauskite ,,Sutinku". Savo suitikimą galite atšaukti bet kada, pakeitę interneto naršyklės nustatymus, ištrindami įrašytus slapukus.</CookieConsent>

        <CssBaseline />

        <AppBar position="sticky">
            <Toolbar sx={{ backgroundColor: "white", display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Stack direction="column" alignItems="center" sx={{ width: "100%" }}>
                    <Stack direction="row" sx={{ justifyContent: "space-between", width: isDesktop ? "1152px" : "100vw", p: isDesktop ? 0 : "0px 15px", alignItems: "center" }}>
                        <Link style={{ flex: 1 }} to="/">
                            <img src={logo} style={{ maxHeight: "25px", maxWidth: "200px" }} alt="" />
                        </Link>
                        <Stack direction={"row"} sx={{ alignItems: "center" }} gap={3}>
                            {isMobile || isTablet ?
                                <>
                                    <MobileMenu menuItems={allMenuItems} />
                                </>
                                :
                                <>
                                    <Menu menuItems={allMenuItems} />
                                    {phoneNumber && <a style={{ color: "#9D6209" }} href={`tel:${phoneNumber}`}>{phoneNumber}</a>}
                                    <UserMenu />
                                </>
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
        <Stack direction="column" sx={{ alignItems: "center", pt: "40px" }}>
            <Box sx={{ width: isDesktop ? "1152px" : "100vw", p: isDesktop ? 0 : "0px 15px" }}>
                <Outlet />
            </Box>
        </Stack>
        <Stack direction="column" sx={{ mt: "30px", width: "100%", background: "white", alignItems: "center", p: "50px 0" }} gap={2}>
            <Typography sx={{ fontSize: "35px", fontWeight: 500, width: "600px", maxWidth: "100%", textAlign: "center" }}>
                Greiti, patikimi kreditai 1kredito!
            </Typography>
            <Divider sx={{ width: "120px" }} />
            <Typography sx={{ fontSize: "16px", fontWeight: 400, width: "600px", maxWidth: "100%", textAlign: "center", p: isDesktop ? 0 : "0px 15px" }}>
            1kreditas yra paskolų palyginimo platforma. Vos kelių mygtukų paspaudimu sutrumpinsite ilgą ir
varginantį procesą kreipiantis į skirtingus kreditus, nes geriausią ir patogiausią sprendimą
paskoloms nuo 50 iki 15 000 eurų gausite net neišėję iš namų!
            </Typography>
        </Stack>
        <Stack direction="column" sx={{ alignItems: "center", pt: "40px", pb: "40px" }}>
            <Box sx={{ width: isDesktop ? "1152px" : "100%", p: isDesktop ? 0 : "0px 15px" }}>
                <Footer />
            </Box>
        </Stack>
    </AuthDialogContext.Provider>
}


export default Layout